import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Customers() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isloading, setIsLoading] = useState(false);

  const [editing, setEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState(false);
  const [column, setColumn] = useState("name");
  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/ownerships?includeAdminUnits=true`)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setData(data?.data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, []);

  function searchCustomer(v) {
    setIsLoading(true);
    fetch(`/api/billing/search/${column}/${v}/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          console.log(data);
          setData(data);
        } else setData(null);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  return (
    <Box>
      <Paper>
        <Box p={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              <Typography variant="h6">Customers</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <FormControl fullWidth variant="standard" margin="dense">
                <InputLabel id="columns-label">Columns</InputLabel>
                <Select
                  labelId="columns-label"
                  label="columns"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setColumn(e.target.value);
                  }}
                  defaultValue={""}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="gender">Gender</MenuItem>
                  <MenuItem value="phoneNumber">Phone Number</MenuItem>
                  <MenuItem value="ownershipType">Ownership Type</MenuItem>
                  <MenuItem value="phoneNumber">Phone No</MenuItem>
                  <MenuItem value="nationalId">National ID</MenuItem>
                  <MenuItem value="postalAddress">Postal Address</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Search"
                variant="standard"
                margin="dense"
                fullWidth
                placeholder="Name or Plot No Search"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setRefresh(!refresh);
                    setOffset(0);
                  } else {
                    searchCustomer(e.target.value);
                  }
                }}
              />
            </Box>
          </Box>
          <Divider />

          {isloading ? (
            <div>Loading...</div>
          ) : (
            <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>ID Number</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.length > 0 ? (
                    data.map((item, index) => (
                      <TableRow
                        key={index}
                        onClick={() => navigate(`/bl/customers/${item.id}`)}
                        hover
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Chip label={offset * 10 + index + 1} />
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.nationalId}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.gender}</TableCell>
                        <TableCell>{item.phoneNumber || "N/A"}</TableCell>
                        <TableCell>{item.postalAddress}</TableCell>
                        <TableCell>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/bl/customers/${item.id}`);
                            }}
                            variant="outlined"
                            size="small"
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        No Customer Records Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
