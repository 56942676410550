import React, { useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Divider,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";

export default function FirstApproval() {
  const [column, setColumn] = useState("OwnerName");
  const [data, setData] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6" color="primary.light">
          Data Validation
        </Typography>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <FormControl fullWidth variant="standard" margin="dense">
            <InputLabel id="columns-label">Columns</InputLabel>
            <Select
              labelId="columns-label"
              id="columns"
              value={column}
              label="Columns"
              onChange={(e) => setColumn(e.target.value)}
            >
              <MenuItem value="OwnerName">Name</MenuItem>
              <MenuItem value="NationalID">National ID</MenuItem>
              <MenuItem value="NewPlotNo">Plot No</MenuItem>
              <MenuItem value="ParcelNo">Parcel No</MenuItem>
              <MenuItem value="LR_Number">Plot No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Search"
            variant="standard"
            margin="dense"
            fullWidth
            placeholder="Name or Plot No Search"
            // onChange={(e) => {
            //   if (e.target.value === "") {
            //     setRefresh(!refresh);
            //     setOffset(0);
            //   } else {
            //     searchCustomer(e.target.value);
            //   }
            // }}
          />
        </Box>
      </Box>
      <Divider />
      {isloading ? (
        <div>Loading...</div>
      ) : (
        <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">No</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone No</TableCell>
                <TableCell>LR No</TableCell>
                <TableCell>Plot No</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log(data)}
              {data?.data?.length > 0 ? (
                data.data.map((item, index) => (
                  <TableRow
                    key={index}
                    // onClick={() => handleRowClick(item)}
                    hover
                  >
                    <TableCell padding="checkbox">
                      <Chip label={offset * 10 + index + 1} />
                    </TableCell>
                    <TableCell>{item.Name}</TableCell>
                    <TableCell>{item.Email}</TableCell>
                    <TableCell>{item.Phone || "N/A"}</TableCell>
                    <TableCell>{item.Department}</TableCell>
                    <TableCell>{item.Position}</TableCell>
                    <TableCell>{item.Role}</TableCell>
                    <TableCell>
                      <Chip
                        color={item.Status ? "success" : "warning"}
                        label={item.Status ? "Active" : "Inactive"}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    No Records Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};