import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const DataValuations = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("plotNo");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValuation, setSelectedValuation] = useState(null);

  useEffect(() => {
    fetchValuationData();
  }, [paginationModel, sortModel, searchValue]);

  const fetchValuationData = async () => {
    setLoading(true);
    try {
      const searchParam = searchValue ? `&${column}=${searchValue}` : "";
      const sortParam =
        sortModel.length > 0
          ? `&sortBy=${sortModel[0].field}&sortOrder=${sortModel[0].sort}`
          : "";
      const response = await fetch(
        `/api/valuations?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${
          paginationModel.pageSize
        }${searchParam}${sortParam}&includeAdminUnits=true`
      );
      const result = await response.json();
      if (response.ok) {
        setData(result);
      }
    } catch (error) {
      console.error("Error fetching valuation data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const handleRowClick = (params) => {
    setSelectedValuation(params.row);
    setOpenDialog(true);
  };

  const columns = [
    { field: "srNo", headerName: "SN" },
    { field: "plotNo", headerName: "Plot No", flex: 0.5 },
    { field: "lrNo", headerName: "LR No", flex: 0.5 },
    { field: "oldLrNo", headerName: "Old LR No", flex: 0.5 },
    { field: "adminUnit.name", headerName: "Situation", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "size", headerName: "Size", flex: 0.5 },
    { field: "cgh", headerName: "CGH", flex: 0.5 },
    { field: "private", headerName: "Private", flex: 0.5 },
  ];

  return (
    <Box>
      <Card sx={{ boxShadow: "0px 8px 32px #60606040", p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" flexGrow={1}>
            Valuation Roll
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FormControl>
              <InputLabel size="small">Search by...</InputLabel>
              <Select
                label="Search by..."
                size="small"
                onChange={(e) => setColumn(e.target.value)}
                value={column}
              >
                <MenuItem value="plotNo">Plot No</MenuItem>
                <MenuItem value="lrNo">LR Number</MenuItem>
                <MenuItem value="use">Land Use</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              label="Search..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
        </Box>

        <DataGrid
          rows={data?.data || []}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          rowCount={data?.total || 0}
          loading={loading}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          onRowClick={handleRowClick}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          disableColumnFilter
          autoHeight
        />
      </Card>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            px: 2,
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Valuation Details</Typography>{" "}
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => setOpenDialog(false)}
          >
            Close
          </Button>{" "}
        </Box>
        <Divider sx={{ my: 1 }} />
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {selectedValuation &&
                  Object.entries(selectedValuation)
                    .filter(
                      ([key]) => !key.includes("id") && key !== "adminUnitId"
                    )
                    .map(([key, value]) => {
                      if (key === "adminUnit" && value) {
                        return Object.entries(value)
                          .filter(([adminKey]) => !adminKey.includes("id"))
                          .map(([adminKey, adminValue]) => (
                            <TableRow key={`adminUnit-${adminKey}`}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ fontWeight: "bold" }}
                              >
                                {adminKey.toUpperCase()}
                              </TableCell>
                              <TableCell>{adminValue}</TableCell>
                            </TableRow>
                          ));
                      }
                      return (
                        <TableRow key={key}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ fontWeight: "bold" }}
                          >
                            {key.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {typeof value === "object" && value !== null ? (
                              <Table size="small">
                                <TableBody>
                                  {Object.entries(value)
                                    .filter(
                                      ([subKey]) =>
                                        ![
                                          "id",
                                          "createdAt",
                                          "updatedAt",
                                        ].includes(subKey)
                                    )
                                    .map(([subKey, subValue]) => (
                                      <TableRow key={subKey}>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          {subKey.toUpperCase()}
                                        </TableCell>
                                        <TableCell>{subValue}</TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            ) : (
                              value
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DataValuations;
