import {
  Box,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

export default function Towns() {
  const [data, setData] = useState({ data: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const fetchAdminUnits = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `/api/adminunits?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}`
      );
      const fetchedData = await response.json();
      if (response.ok) {
        const enhancedData = await enhanceData(fetchedData.data);
        setData({ data: enhancedData, total: fetchedData.total });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [paginationModel]);

  useEffect(() => {
    if (searchValue === "") {
      fetchAdminUnits();
    }
  }, [paginationModel, searchValue, fetchAdminUnits]);

  const enhanceData = async (rowData) => {
    try {
      return await Promise.all(
        rowData.map(async (item) => {
          try {
            const summaryResponse = await fetch(
              `/api/adminunits/summary/${item.id}`
            );
            const summary = await summaryResponse.json();
            return {
              ...item,
              parcels: summary.parcels,
              searches: summary.searches,
              paid: summary.paid,
              arrears: summary.arrears,
            };
          } catch (error) {
            console.error(`Error fetching summary for item ${item.id}:`, error);
            return { ...item, parcels: 0, searches: 0, paid: 0, arrears: 0 };
          }
        })
      );
    } catch (error) {
      console.error("Error enhancing data:", error);
      return rowData;
    }
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (value !== "") {
      try {
        setIsLoading(true);
        const response = await fetch(
          `/api/adminunits?offset=0&limit=${paginationModel.pageSize}&${column}=${value}`
        );
        const fetchedData = await response.json();
        if (response.ok) {
          const enhancedData = await enhanceData(fetchedData.data);
          setData({ data: enhancedData, total: fetchedData.total });
        }
      } catch (error) {
        console.error("Error searching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const columns = [
    {
      headerName: "No",
      renderCell: (params) => {
        const index = data.data.findIndex((e) => e.id === params?.row?.id);
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "subCounty	",
      headerName: "Subcounty",
      flex: 1,
      renderCell: (params) => params?.row?.subCounty || "N/A",
    },
    { field: "ward", headerName: "Ward", flex: 1 },
    {
      field: "parcels",
      headerName: "Parcels",
      renderCell: (params) => params.value.toLocaleString() || 0,
    },
    {
      field: "searches",
      headerName: "Searches",
      renderCell: (params) => params.value.toLocaleString() || 0,
    },
    {
      field: "paid",
      headerName: "Paid",
      minWidth: 120,
      renderCell: (params) => (
        <Chip
          size="small"
          label={`KSh ${(params.value || 0).toLocaleString("en-KE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
          color={params.value > 0 ? "success" : "default"}
          variant="outlined"
        />
      ),
    },
    {
      field: "arrears",
      headerName: "Arrears",
      minWidth: 120,
      renderCell: (params) => (
        <Chip
          size="small"
          label={`KSh ${(params.value || 0).toLocaleString("en-KE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
          color={params.value === 0 ? "success" : "error"}
          variant="outlined"
        />
      ),
    },
  ];

  return (
    <>
      <Box display="flex" gap={2}>
        <Typography variant="h6" color="primary.light">
          Towns
        </Typography>
        <Box flexGrow={1}></Box>
        <FormControl>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
          >
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="subCounty">Sub County</MenuItem>
            <MenuItem value="ward">Ward</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>
      <Divider sx={{ my: 1 }} />
      <DataGrid
        rows={data.data}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50]}
        rowCount={data.total}
        paginationMode="server"
        loading={isLoading}
        disableSelectionOnClick
        onRowClick={(params) => navigate(`/li/parcels/${params?.row?.id}`)}
      />
    </>
  );
}
