import "../Styles/login.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  Snackbar,
  Alert,
  Grid2,
  Container,
  Stack,
  Divider,
} from "@mui/material";
const lg1 = require("../assets/imgs/bg1.jpg");
const lg2 = require("../assets/imgs/bg2.jpg");
const lg3 = require("../assets/imgs/bg3.jpg");
const lg4 = require("../assets/imgs/bg4.jpg");
const logo = require("../assets/images/lg.png");

const images = [lg1, lg2, lg3, lg4];

export default function LoginPage(props) {
  const rfEmail = useRef();
  const rsEmail = useRef();
  const rfPassword = useRef();
  const code = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, updateBody] = useState({
    Email: null,
  });

  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const login = async () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email)) {
      setIsErr("Please Enter a Valid Email Address!");
      setSnackbarOpen(true);
      return;
    }
    if (!validatePassword(body.Password)) {
      setIsErr("Password must be at least 6 characters!");
      setSnackbarOpen(true);
      return;
    }

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setLoading(true);
      try {
        const response = await fetch("/api/admin/login", {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        if (!response.ok) {
          setLoading(false);
          setIsErr(data.message);
          setSnackbarOpen(true);
        } else {
          setIsErr(data.message);
          localStorage.setItem("gdfhgfhtkn", data.token);
          setLoading(false);
          setTimeout(() => {
            navigate("/home");
          }, 500);
        }
      } catch (err) {
        setLoading(false);
        setIsErr("Login failed");
        setSnackbarOpen(true);
      }
    }
  };

  const reset = () => {
    let d = body;
    d.Email = rsEmail.current.value.toLowerCase().trim();
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email)) {
      setIsErr("Please Enter a Valid Email Address!");
      setSnackbarOpen(true);
      return;
    }

    if (validateEmail(body.Email)) {
      setLoading(true);
      fetch("/api/auth/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("gdfhgfhtkngdfhgfhtkn", data.token);
            localStorage.removeItem("path");
            setIsErr(data.success);
            setLoading(false);
            navigate("/home");
          } else {
            setLoading(false);
            setIsErr(data.error);
            setSnackbarOpen(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsErr("Login failed");
          setSnackbarOpen(true);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/.,;:\s@"]+(\.[^<>()[\]/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  useEffect(() => {
    let currentIndex = 0;
    const backgroundElement = document.querySelector(".login-background");

    const changeBackground = () => {
      currentIndex = (currentIndex + 1) % images.length;
      backgroundElement.style.backgroundImage = `url(${images[currentIndex]})`;
    };

    const intervalId = setInterval(changeBackground, 5000); // Change every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      position="relative"
      sx={{ overflow: "auto", py: { xs: 4, md: 0 } }}
    >
      <div
        className="login-background"
        style={{ backgroundImage: `url(${images[0]})` }}
      ></div>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: { xs: 4, md: 0 },
        }}
      >
        <Container>
          <Grid2 container spacing={{ xs: 4, md: 2 }} alignItems="center">
            <Grid2 size={{ xs: 12, md: 6, lg: 6 }}>
              <Stack spacing={4}>
                <img
                  src={logo}
                  alt="LIMS Logo"
                  style={{ width: 150, height: 150 }}
                />
                <Typography
                  variant="h2"
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" },
                    textAlign: { xs: "center", md: "left" },
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  }}
                >
                  Homabay County
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#fff",
                    fontSize: { xs: ".5rem", sm: "1rem", md: "1.5rem" },
                    textAlign: { xs: "center", md: "left" },
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  }}
                >
                  Land Information Management <br /> System (LIMS)
                </Typography>
              </Stack>
            </Grid2>
            <Grid2
              size={{ xs: 12, md: 6, lg: 6 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  md: "flex-end",
                  xl: "flex-end",
                },
              }}
            >
              <Card
                elevation={3}
                sx={{
                  px: 4,
                  py: 2,
                  maxWidth: 350,
                  width: "100%",
                  borderRadius: 4,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  boxShadow: 3,
                }}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontWeight="bold"
                    color="primary.light"
                    variant="h5"
                    sx={{ mb: 1 }}
                  >
                    Staff Sign In
                  </Typography>
                  <TextField
                    inputRef={rfEmail}
                    type="email"
                    label="Email"
                    fullWidth
                    margin="normal"
                    size="small"
                  />
                  <TextField
                    inputRef={rfPassword}
                    type="password"
                    label="Password"
                    fullWidth
                    margin="normal"
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    color="primary.light"
                    align="center"
                    sx={{ mt: 1, cursor: "pointer" }}
                    onClick={() => setOpenResetDialog(true)}
                  >
                    Forgot password? Click here
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={login}
                    fullWidth
                    size="small"
                    sx={{ mt: 2, py: 1.5 }}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Sign In"}
                  </Button>
                </form>
              </Card>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
      <Dialog
        open={openResetDialog}
        onClose={() => setOpenResetDialog(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Reset Password</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Please enter your email address to reset your password.
          </DialogContentText>
          <TextField
            inputRef={rsEmail}
            type="email"
            label="Email"
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenResetDialog(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={reset}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {isErr}
        </Alert>
      </Snackbar>
    </Box>
  );
}
