import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TablePagination,
  Stack,
  Alert,
  Divider,
  Grid2,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Import county data
import countyData from "../../assets/data/county_data.json";

const CSCustomers = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [creating, setCreating] = useState(false);
  const [ownerData, setOwnerData] = useState({
    name: "",
    gender: "",
    ownershipType: "",
    phoneNumber: "",
    altPhoneNumber: "",
    nationalId: "",
    county: "",
    subCounty: "",
    ward: "",
    postalAddress: "",
    physicalAddress: "",
    email: "",
  });
  const [subCounties, setSubCounties] = useState([]);
  const [wards, setWards] = useState([]);

  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [changed, setChanged] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchValue === "") {
      fetchOwnershipData();
    }
  }, [changed, offset, limit, searchValue]);

  const fetchOwnershipData = async () => {
    try {
      setData(null);
      setLoading(true);
      const response = await fetch(
        `/api/ownerships?offset=${offset * limit}&limit=${limit}`
      );
      const data = await response.json();
      if (response.ok) {
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (value) => {
    if (value !== "") {
      setSearchValue(value);
      setData(null);
      setOffset(0);
      setLoading(true);
      try {
        const response = await fetch(
          `/api/ownerships?offset=${
            offset * limit
          }&limit=${limit}&${column}=${value}`
        );
        const data = await response.json();
        if (response.ok) {
          setData(data);
        }
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchValue("");
      setChanged(!changed);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setOwnerData({
      name: "",
      gender: "",
      ownershipType: "",
      phoneNumber: "",
      nationalId: "",
      county: "",
      subCounty: "",
      ward: "",
      postalAddress: "",
      email: "",
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setError("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOwnerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCountyChange = (event) => {
    const selectedCounty = event.target.value;
    setOwnerData((prev) => ({
      ...prev,
      county: selectedCounty,
      subCounty: "",
      ward: "",
    }));
    setSubCounties(Object.keys(countyData[selectedCounty] || {}));
    setWards([]);
  };

  const handleSubCountyChange = (event) => {
    const selectedSubCounty = event.target.value;
    setOwnerData((prev) => ({
      ...prev,
      subCounty: selectedSubCounty,
      ward: "",
    }));
    setWards(countyData[ownerData.county]?.[selectedSubCounty] || []);
  };

  const handleSaveOwner = async () => {
    if (
      !ownerData.name ||
      !ownerData.nationalId ||
      !ownerData.phoneNumber ||
      !ownerData.email ||
      !ownerData.gender ||
      !ownerData.ownershipType ||
      !ownerData.county ||
      !ownerData.subCounty ||
      !ownerData.ward
    ) {
      setError("Please fill in all required fields.");
      return;
    }
    setCreating(true);
    try {
      const response = await fetch("/api/ownerships", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(ownerData),
      });
      const body = await response.json();
      if (response.ok) {
        setError(body?.message || "Owner saved successfully!");
        setTimeout(() => {
          fetchOwnershipData();
          handleCloseDialog();
        }, 1000);
      } else {
        setError(body?.message || "Error saving owner.");
      }
    } catch (error) {
      setError("Error saving owner. Please try again.");
    } finally {
      setCreating(false);
    }
  };

  return (
    <Box>
      <Card sx={{ boxShadow: "0px 8px 32px #60606040", p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>
          <Box flexGrow={1}>
            <Typography variant="">Land Owners</Typography>
          </Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="subCounty">Sub County</MenuItem>
              <MenuItem value="ward">Ward</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>County</TableCell>
                <TableCell>Sub County</TableCell>
                <TableCell>Ward</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={10}>Loading data...</TableCell>
                </TableRow>
              ) : (
                <>
                  {data && data.data.length > 0 ? (
                    data.data.map((owner, index) => (
                      <TableRow
                        onClick={() => {
                          window.location.href = "/cs/customers/" + owner.id;
                        }}
                        key={owner.id}
                      >
                        <TableCell>{offset * limit + index + 1}</TableCell>
                        <TableCell>{owner.name}</TableCell>
                        <TableCell>{owner.gender}</TableCell>
                        <TableCell>{owner.ownershipType}</TableCell>
                        <TableCell>{owner.phoneNumber}</TableCell>
                        <TableCell>{owner.email}</TableCell>
                        <TableCell>{owner.county}</TableCell>
                        <TableCell>{owner.subCounty}</TableCell>
                        <TableCell>{owner.ward}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>No data found</TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data ? data.total : 0}
            page={offset}
            onPageChange={(e, page) => setOffset(page)}
            rowsPerPage={limit}
            onRowsPerPageChange={(e) => setLimit(parseInt(e.target.value, 10))}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </TableContainer>
      </Card>
    </Box>
  );
};

export default CSCustomers;
