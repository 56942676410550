import {
  getArea as getGeodeticArea,
  getLength as getGeodeticLength,
} from "ol/sphere";
import { useNavigate } from "react-router-dom";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Map from "ol/Map";
import { useState, useRef, useEffect } from "react";
import { OSM, XYZ } from "ol/source";
import {
  ZoomToExtent,
  defaults as defaultControls,
  ScaleLine,
  FullScreen,
  Attribution,
  Rotate,
  ZoomSlider,
} from "ol/control";
import { LineString, Circle as CircleGeom, Point, MultiPolygon } from "ol/geom";
import { Draw } from "ol/interaction";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON";
import {
  Checkbox,
  Box,
  Typography,
  Card,
  Divider,
  Paper,
  List,
  ListItem,
  ButtonGroup,
  Button,
  CircularProgress,
  Grid2,
} from "@mui/material";
import { fromLonLat, transform } from "ol/proj";
import {
  ArrowBack,
  CancelOutlined,
  RectangleOutlined,
  Timeline,
  TripOrigin,
} from "@mui/icons-material";
import FilterAndStyleBar from "./FilterAndStyleBar";
import { Layers, Satellite, Terrain } from "@mui/icons-material";
import proj4 from "proj4";
import { register } from "ol/proj/proj4.js";
import Projection from "ol/proj/Projection.js";
import { Feature } from "ol";
proj4.defs(
  "EPSG:21036",
  "+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs"
);
register(proj4);

export default function ParcelsMap(props) {
  const id = window.location.pathname.split("/")[3];
  const [map, setMap] = useState(null);
  const [drawInteraction, setDrawInteraction] = useState(null);
  const mapRef = useRef();
  mapRef.current = map;
  const popupRef = useRef();
  const [popupContent, setPopupContent] = useState(null);
  const [loadingLayers, setLoadingLayers] = useState([]);
  const measureSource = new VectorSource();
  const [featureSelectEnabled, setFeatureSelectEnabled] = useState(true);
  const [activeBasemap, setActiveBasemap] = useState("OSM");
  const navigate = useNavigate();
  const legendItems = [
    {
      label: "Subcounty Boundary",
      stroke: "#48CFCB",
      fill: "white",
      radius: 6,
      width: 2,
      table: "AdminUnits",
    },
    {
      label: "Ward Boundary",
      stroke: "#ED3EF7",
      fill: "white",
      radius: 4,
      width: 2,
      table: "AdminUnits",
    },
    {
      label: "Land Parcels",
      stroke: "white",
      fill: "#0D7C66",
      radius: 8,
      width: 2,
      table: "MasterMeters",
    },
    {
      label: "Beacons",
      stroke: "white",
      fill: "orange",
      radius: 8,
      width: 2,
      table: "BulkMeters",
    },
  ];

  const toggleBasemap = (label) => {
    setActiveBasemap(label);
    basemaps.forEach((basemap) => {
      basemap.layer.setVisible(basemap.label === label);
    });
  };
  // Create the map instance
  useEffect(() => {
    const projection = new Projection({
      code: "EPSG:21036",
      units: "m",
      extent: [-20037508, -20048966, 20037508, 20048966],
      worldExtent: [-180, -85, 180, 85],
    });

    const initialCenter = transform([36.9, -0.5], "EPSG:4326", "EPSG:21036");

    const initialMap = new Map({
      target: "map",
      layers: basemaps.map((b) => b.layer),
      view: new View({
        zoom: 10,
        projection: projection,
        center: initialCenter,
      }),
      controls: defaultControls().extend([
        new ScaleLine(),
        new FullScreen(),
        new Attribution(),
        new Rotate(),
        new ZoomSlider(),
        new ZoomToExtent({
          extent: [
            633401.3446711299475282, 9919002.521270414814353,
            693716.2872443984961137, 9961211.8638889770954847,
          ],
        }),
      ]),
    });
    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  const fetchAndDisplayLayer = async (endpoint, layerTitle, style) => {
    try {
      setLoadingLayers(prev => [...prev, layerTitle]);
      const response = await fetch(endpoint);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (Array.isArray(data)) {
        const features = data.map(item => {
          if (item.geom && item.geom.coordinates) {
            const feature = new Feature({
              geometry: new MultiPolygon(item.geom.coordinates)
            });
            
            // Set properties
            Object.entries(item).forEach(([key, value]) => {
              if (typeof value !== "object") {
                feature.set(key, value);
              }
            });
            
            // Set style
            feature.setStyle(style);
            
            return feature;
          }
        }).filter(Boolean);

        const vectorSource = new VectorSource({
          features: features
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          title: layerTitle
        });

        mapRef.current.addLayer(vectorLayer);

        // Fit view to the extent if it's the first layer
        if (layerTitle === "Land Parcels") {
          const extent = vectorSource.getExtent();
          mapRef.current.getView().fit(extent, {
            padding: [20, 20, 20, 20],
            duration: 1000
          });
        }
      }
    } catch (error) {
      console.error(`Error fetching ${layerTitle}:`, error);
    } finally {
      setLoadingLayers(prev => prev.filter(layer => layer !== layerTitle));
    }
  };

  const fetchAndDisplayPolygons = async () => {
    // Land Parcels
    await fetchAndDisplayLayer(
      `/api/landparcels/adminUnit/${id}`,
      "Land Parcels",
      new Style({
        fill: new Fill({ color: "rgba(13, 124, 102, 0.4)" }),
        stroke: new Stroke({ color: "#0D7C66", width: 1 })
      })
    );

    // Subcounties
    await fetchAndDisplayLayer(
      `/api/landparcels/boundaries?subcounty=true`,
      "Subcounty Boundary",
      new Style({
        fill: new Fill({ color: "rgba(72, 207, 203, 0.2)" }),
        stroke: new Stroke({ color: "#48CFCB", width: 2 })
      })
    );

    // Wards
    await fetchAndDisplayLayer(
      `/api/landparcels/boundaries?ward=true`,
      "Ward Boundary",
      new Style({
        fill: new Fill({ color: "rgba(237, 62, 247, 0.2)" }),
        stroke: new Stroke({ color: "#ED3EF7", width: 2 })
      })
    );
  };

  useEffect(() => {
    fetchAndDisplayPolygons();
  }, [id]);

  useEffect(() => {
    if (map) {
      const popupOverlay = new Overlay({
        element: popupRef.current,
        positioning: "bottom-center",
        stopEvent: false,
      });
      map.addOverlay(popupOverlay);
      map.on("singleclick", function (event) {
        if (featureSelectEnabled) {
          const features = map.getFeaturesAtPixel(event.pixel);

          if (features.length > 0) {
            const properties = features[0].getProperties();
            console.log(properties);

            delete properties.geometry;
            delete properties.ID;
            delete properties.createdAt;
            delete properties.updatedAt;
            delete properties.User;
            delete properties.Coordinates;
            delete properties.Picture;

            if (properties?.RecTime != undefined) {
              properties.RecTime = properties.RecTime.split(" ")[0];
            }
            if (properties?.FRecTime != undefined) {
              properties.FRecTime = properties.FRecTime.split(" ")[0];
            }

            setPopupContent(properties);
            popupOverlay.setPosition(event.coordinate);
          } else {
            setPopupContent(null);
            popupOverlay.setPosition(undefined);
          }
        }
      });
    }
  }, [map, featureSelectEnabled]);

  const addDrawInteraction = (type) => {
    if (drawInteraction) {
      map.removeInteraction(drawInteraction);
    }
    setFeatureSelectEnabled(false);

    const draw = new Draw({
      source: measureSource,
      type: type === "circle" ? "Circle" : type,
    });

    let sketch;
    let measureTooltipElement;
    let measureTooltip;

    const formatLengthOrArea = (geom) => {
      let output;

      if (geom instanceof LineString) {
        // Get length directly from the geometry in UTM (meters)
        const length = geom.getLength();
        output = `D: ${length.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} m`;
      } else if (geom instanceof MultiPolygon) {
        // Get area directly from the geometry in UTM (square meters)
        const area = geom.getArea();
        output = `A: ${area.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} sq m`;
      } else if (geom instanceof CircleGeom) {
        // Calculate radius and area for a Circle
        const radius = geom.getRadius(); // Radius is in meters in UTM
        const area = Math.PI * Math.pow(radius, 2); // Area in square meters
        output = `R: ${radius.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} m, <br /> A: ${area.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })} sq m`;
      }

      return output;
    };

    const createMeasureTooltip = () => {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement("div");
      measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
      });
      map.addOverlay(measureTooltip);
    };

    draw.on("drawstart", (evt) => {
      sketch = evt.feature;

      let tooltipCoord = evt.coordinate;

      sketch.getGeometry().on("change", (event) => {
        const geom = event.target;
        const output = formatLengthOrArea(geom);
        tooltipCoord = geom.getLastCoordinate();

        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      });
    });

    draw.on("drawend", (event) => {
      const geom = event.feature.getGeometry();
      const output = formatLengthOrArea(geom);
      const tooltipCoord = getCenterCoordinate(geom);

      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);

      measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      measureTooltip.setOffset([0, 5]);

      sketch = null;
      measureTooltipElement = null;
      createMeasureTooltip();

      // Ensure the sketch is added to the map layer (measureSource)
      const vectorLayer = new VectorLayer({
        title: "measure",
        source: measureSource,
        style: new Style({
          stroke: new Stroke({
            color: "#9acd32",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
          image: new CircleStyle({
            radius: 7,
            stroke: new Stroke({
              color: "#9acd32",
              width: 2,
            }),
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
          }),
        }),
      });
      map.addLayer(vectorLayer);
    });

    createMeasureTooltip();
    map.addInteraction(draw);
    setDrawInteraction(draw);
  };

  const clearMeasurements = () => {
    setFeatureSelectEnabled(true); // Re-enable feature selection after drawing
    measureSource.clear(); // Clear the vector source
    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer && layer.get("title") === "measure") {
        map.removeLayer(layer); // Remove the measurement layer
      }
    });
    if (drawInteraction) {
      map.removeInteraction(drawInteraction);
    }
    map.getOverlays().clear(); // Remove any measurement overlays
  };

  const getCenterCoordinate = (geom) => {
    if (geom instanceof LineString) {
      // Calculate the midpoint of the LineString
      const coordinates = geom.getCoordinates();
      const midIndex = Math.floor(coordinates.length / 2);
      return coordinates[midIndex];
    } else if (geom instanceof MultiPolygon) {
      // Calculate the centroid of the Polygon
      const extent = geom.getExtent();
      return [
        (extent[0] + extent[2]) / 2, // (minX + maxX) / 2
        (extent[1] + extent[3]) / 2, // (minY + maxY) / 2
      ];
    } else if (geom instanceof CircleGeom) {
      // Get the center of the Circle
      return geom.getCenter();
    }
    return null;
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 16px #60606040",

        p: 2,
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
        <Typography variant="h6" fontWeight={600} flex={1}>
          Land Parcel Map
        </Typography>
        <Button size="small" onClick={() => navigate(-1)}>
          <ArrowBack fontSize="small" />
        </Button>
      </Box>
      <Box sx={{ position: "relative" }}>
        <div id="map" style={{ width: "100%", height: "80vh" }} />
        <FilterAndStyleBar legendItems={legendItems} map={map} />
        <Box
          sx={{
            position: "absolute",
            bottom: "3.3em",
            left: "1.5em",
            zIndex: 12,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ButtonGroup orientation="vertical">
            {basemaps.map((basemap, index) => (
              <Button
                size="small"
                key={index}
                onClick={() => toggleBasemap(basemap.label)}
                variant={
                  activeBasemap === basemap.label ? "contained" : "outlined"
                }
              >
                {basemap.icon}
              </Button>
            ))}
          </ButtonGroup>
        </Box>

        <Card
          sx={{
            position: "absolute",
            bottom: "2.5rem",
            backgroundColor: "rgba(255,255,255,0.7)",
            right: "1.5em",
            py: 1,
            px: 2,
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6">Legend</Typography>
          <Divider sx={{ mb: "10px" }} />
          {legendItems.map((item, i) => (
            <LegendItem key={i} item={item} map={map} />
          ))}
        </Card>
        <Paper
          ref={popupRef}
          sx={{
            position: "absolute",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "8px",
            display: popupContent ? "block" : "none",
            zIndex: 10,
            transform: "translate(-50%, -100%)",
          }}
        >
          {popupContent && (
            <Popup popupContent={popupContent} popupRef={popupRef} />
          )}
        </Paper>

        <Box
          sx={{
            position: "absolute",
            top: "20%",
            right: "1.5em",
            zIndex: 11,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ButtonGroup orientation="vertical">
            <Button
              size="small"
              onClick={() => addDrawInteraction("LineString")}
            >
              <Timeline fontSize="small" />
            </Button>
            <Button size="small" onClick={() => addDrawInteraction("Polygon")}>
              <RectangleOutlined fontSize="small" />
            </Button>
            <Button size="small" onClick={() => addDrawInteraction("circle")}>
              <TripOrigin fontSize="small" />
            </Button>
            <Button size="small" onClick={clearMeasurements}>
              <CancelOutlined fontSize="small" />
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "2.5rem",
            right: "1.5em",
            zIndex: 11,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {loadingLayers.map((label, index) => (
            <LoadingBar key={index} label={label} />
          ))}
        </Box>
      </Box>
    </Card>
  );
}

const basemaps = [
  {
    label: "OSM",
    layer: new TileLayer({
      source: new OSM(),
      title: "OSM",
      visible: true,
    }),
    icon: <Layers fontSize="small" />,
  },
  {
    label: "Satellite",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/mapServer/tile/{z}/{y}/{x}", // Replace this with a proper Satellite tile URL
      }),
      title: "Satellite",
      visible: false,
    }),
    icon: <Satellite fontSize="small" />,
  },
  {
    label: "Terrain",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/mapServer/tile/{z}/{y}/{x}", // OpenTopoMap
      }),
      title: "Terrain",
      visible: false,
    }),
    icon: <Terrain fontSize="small" />,
  },
];

const LegendItem = (props) => {
  const [showing, setShowing] = useState(true);

  useEffect(() => {
    if (props.map) {
      props.map.getLayers().forEach((layer) => {
        if (layer && layer.get("title") === props.item.label) {
          layer.setVisible(showing);
        }
      });
    }
  }, [showing, props.map, props.item.label]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: "3px",
        gap: 1,
      }}
    >
      <Checkbox
        checked={showing}
        sx={{
          height: 10,
          width: 10,
        }}
        onChange={(e) => setShowing(e.target.checked)}
      />
      <Box
        sx={{
          border: `1px solid ${props.item.stroke}`,
          backgroundColor: props.item.fill,
          height: 16,
          width: 16,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Typography variant="body2">{props.item.label}</Typography>
    </Box>
  );
};

const LoadingBar = ({ label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "8px",
        borderRadius: "8px",
        mb: 1,
      }}
    >
      <CircularProgress size={20} sx={{ mr: 1 }} />
      <Typography variant="body2">{label} is loading...</Typography>
    </Box>
  );
};

const Popup = ({ popupRef, popupContent }) => {
  const [owner, setOwner] = useState(null);
  const [valuation, setValuation] = useState(null);
  const [billing, setBilling] = useState(null);

  return (
    <Paper
      ref={popupRef}
      sx={{
        position: "absolute",
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "8px",
        display: popupContent ? "block" : "none",
        zIndex: 10,
        transform: "translate(-50%, -100%)",
        minWidth: { xs: "85vw", md: "30vw" },
      }}
    >
      <Typography variant="subtitle1">Feature Details</Typography>
      <Divider sx={{ mb: "5px" }} />
      <Card
        sx={{
          borderRadius: "8px",
          p: 2,
          boxShadow: "0px 4px 16px #60606030",
        }}
      >
        <Typography>Land Parcel Details</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2">
          <strong>Area:</strong> {popupContent.area}
        </Typography>
        <Typography variant="body2">
          <strong>Plot No:</strong> {popupContent.plotNo}
        </Typography>
        <Typography variant="body2">
          <strong>LR No:</strong> {popupContent.lrNo || "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>FR No:</strong> {popupContent.frNo || "N/A"}
        </Typography>
        <Typography variant="body2">
          <strong>Created At:</strong>{" "}
          {new Date(popupContent.createdAt).toLocaleString()}
        </Typography>
        <Typography variant="body2">
          <strong>Updated At:</strong>{" "}
          {new Date(popupContent.updatedAt).toLocaleString()}
        </Typography>
      </Card>
    </Paper>
  );
};
