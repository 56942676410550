import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Alert,
  Divider,
  Grid2,
  Typography,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
// Import county data
import countyData from "../../assets/data/county_data.json";

const DataOwnerships = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [creating, setCreating] = useState(false);
  const [ownerData, setOwnerData] = useState({
    name: "",
    gender: "",
    ownershipType: "",
    phoneNumber: "",
    altPhoneNumber: "",
    nationalId: "",
    county: "",
    subCounty: "",
    ward: "",
    postalAddress: "",
    physicalAddress: "",
    email: "",
    srNo: "",
    lrNo: "",
    oldLrNo: "",
  });
  const [subCounties, setSubCounties] = useState([]);
  const [wards, setWards] = useState([]);
  const navigate = useNavigate();
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchValue === "") {
      fetchOwnershipData();
    }
  }, [paginationModel, searchValue]);

  const fetchOwnershipData = async () => {
    try {
      setData(null);
      setLoading(true);
      const response = await fetch(
        `/api/ownerships?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}`
      );
      const data = await response.json();
      if (response.ok) {
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (value) => {
    if (value !== "") {
      setSearchValue(value);
      setData(null);
      setPaginationModel({ ...paginationModel, page: 0 });
      setLoading(true);
      try {
        const response = await fetch(
          `/api/ownerships?offset=${
            paginationModel.page * paginationModel.pageSize
          }&limit=${paginationModel.pageSize}&${column}=${value}`
        );
        const data = await response.json();
        if (response.ok) {
          setData(data);
        }
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchValue("");
      fetchOwnershipData();
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setOwnerData({
      name: "",
      gender: "",
      ownershipType: "",
      phoneNumber: "",
      nationalId: "",
      county: "",
      subCounty: "",
      ward: "",
      postalAddress: "",
      email: "",
      srNo: "",
      lrNo: "",
      oldLrNo: "",
      altPhoneNumber: "",
      physicalAddress: "",
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setError("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOwnerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCountyChange = (event) => {
    const selectedCounty = event.target.value;
    setOwnerData((prev) => ({
      ...prev,
      county: selectedCounty,
      subCounty: "",
      ward: "",
    }));
    setSubCounties(Object.keys(countyData[selectedCounty] || {}));
    setWards([]);
  };

  const handleSubCountyChange = (event) => {
    const selectedSubCounty = event.target.value;
    setOwnerData((prev) => ({
      ...prev,
      subCounty: selectedSubCounty,
      ward: "",
    }));
    setWards(countyData[ownerData.county]?.[selectedSubCounty] || []);
  };

  const handleSaveOwner = async () => {
    setCreating(true);
    try {
      const response = await fetch("/api/ownerships", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(ownerData),
      });
      const body = await response.json();

      if (response.ok) {
        setError(body?.message || "Owner saved successfully!");
        setTimeout(() => {
          fetchOwnershipData();
          handleCloseDialog();
        }, 1000);
      } else {
        setError(
          body?.message ||
            "Error saving owner. Please check the required fields."
        );
      }
    } catch (error) {
      setError("Network error. Please try again.");
    } finally {
      setCreating(false);
    }
  };

  const columns = [
    {
      headerName: "No",
      renderCell: (params) => {
        const index = data
          ? data.data.map((e) => e.id).indexOf(params?.row?.id)
          : 0;
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "nationalId", headerName: "ID" },
    { field: "gender", headerName: "Gender" },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "county", headerName: "County" },
    { field: "phoneNumber", headerName: "Phone", flex: 1 },
  ];

  return (
    <Box>
      <Card sx={{ boxShadow: "0px 8px 32px #60606040", p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>
          <Box flexGrow={1}>
            <Typography variant="title">Owners</Typography>
          </Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="phoneNumber">Phone Number</MenuItem>
              <MenuItem value="nationalId">National ID</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="subCounty">Sub County</MenuItem>
              <MenuItem value="ward">Ward</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenDialog}
            sx={{ color: "white", textTransform: "capitalize" }}
          >
            Create New
          </Button>
        </Box>

        <DataGrid
          rows={
            data
              ? data.data.map((owner, index) => ({
                  ...owner,
                  index: index + 1,
                }))
              : []
          }
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          rowCount={data ? data.total : 0}
          loading={loading}
          paginationMode="server"
          disableColumnFilter
          autoHeight
          onRowClick={(params) => {
            console.log(params.row);
            navigate(`/dc/owners/${params.row.id}`);
          }}
        />
      </Card>

      <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Create Owner</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Owner Name"
              variant="outlined"
              name="name"
              value={ownerData.name}
              onChange={handleInputChange}
              sx={{ mt: 1 }}
            />
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  name="phoneNumber"
                  value={ownerData.phoneNumber}
                  onChange={handleInputChange}
                  sx={{ mt: 1 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="National ID"
                  variant="outlined"
                  name="nationalId"
                  value={ownerData.nationalId}
                  onChange={handleInputChange}
                  sx={{ mt: 1 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={ownerData.email}
                  onChange={handleInputChange}
                  sx={{ mt: 1 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label="Alternative Phone"
                  variant="outlined"
                  name="altPhoneNumber"
                  value={ownerData.altPhoneNumber}
                  onChange={handleInputChange}
                  sx={{ mt: 1 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    fullWidth
                    label="Type"
                    name="ownershipType"
                    value={ownerData.ownershipType}
                    onChange={handleInputChange}
                  >
                    {["Individual", "Company", "Government", "Trust"].map(
                      (type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    fullWidth
                    label="Gender"
                    name="gender"
                    value={ownerData.gender}
                    onChange={handleInputChange}
                  >
                    {["Male", "Female"].map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth>
                  <InputLabel>County</InputLabel>
                  <Select
                    fullWidth
                    label="County"
                    name="county"
                    value={ownerData.county}
                    onChange={handleCountyChange}
                  >
                    {Object.keys(countyData).map((county) => (
                      <MenuItem key={county} value={county}>
                        {county}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth>
                  <InputLabel>Sub County</InputLabel>
                  <Select
                    fullWidth
                    label="Sub County"
                    name="subCounty"
                    value={ownerData.subCounty}
                    onChange={handleSubCountyChange}
                    disabled={!subCounties.length}
                  >
                    {subCounties.map((subCounty) => (
                      <MenuItem key={subCounty} value={subCounty}>
                        {subCounty}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth>
                  <InputLabel>Ward</InputLabel>
                  <Select
                    fullWidth
                    label="Ward"
                    name="ward"
                    value={ownerData.ward}
                    onChange={handleInputChange}
                    disabled={!wards.length}
                  >
                    {wards.map((ward) => (
                      <MenuItem key={ward} value={ward}>
                        {ward}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
            <TextField
              fullWidth
              label="Physical Address"
              variant="outlined"
              name="physicalAddress"
              value={ownerData.physicalAddress}
              onChange={handleInputChange}
              sx={{ mt: 1 }}
            />
            <TextField
              fullWidth
              label="Postal Address"
              variant="outlined"
              name="postalAddress"
              value={ownerData.postalAddress}
              onChange={handleInputChange}
              sx={{ mt: 1 }}
            />
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="SR Number"
                  variant="outlined"
                  name="srNo"
                  value={ownerData.srNo}
                  onChange={handleInputChange}
                  sx={{ mt: 1 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="LR Number"
                  variant="outlined"
                  name="lrNo"
                  value={ownerData.lrNo}
                  onChange={handleInputChange}
                  sx={{ mt: 1 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  fullWidth
                  label="Old LR Number"
                  variant="outlined"
                  name="oldLrNo"
                  value={ownerData.oldLrNo}
                  onChange={handleInputChange}
                  sx={{ mt: 1 }}
                />
              </Grid2>
            </Grid2>
            {error && (
              <Alert color={error.includes("success") ? "success" : "warning"}>
                {error}
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveOwner}
            color="primary"
            variant="contained"
            disabled={creating}
          >
            {creating ? "Processing..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DataOwnerships;
