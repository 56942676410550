import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Grid2, IconButton, Card } from "@mui/material";
import SingleParcel from "./SingleParcel";
import Download from "@mui/icons-material/Download";

export default function ParcelDetailPage() {
  const [parcelData, setParcelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const id = window.location.pathname.split("/")[3]; // Get the ID from the URL

  useEffect(() => {
    setLoading(true);
    fetch(`/api/search/${id}`, { method: "GET" }) // Fetch parcel details using the ID
      .then((res) => res.json())
      .then((data) => {
        setParcelData(data); // Assuming response structure contains the parcel data
        setLoading(false);
      })
      .catch((e) => {
        console.error("Error fetching parcel data:", e);
        setLoading(false);
      });
  }, [id]); // Re-run when the ID changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!parcelData) {
    return <div>No parcel data found.</div>;
  }

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: "0px 4px 20px #60606030",
      }}
      component={Card}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" color="primary.light">
          Parcel Information
        </Typography>
        <IconButton
          edge="end"
          color="primary"
          onClick={() => {
            /* Add download functionality here */
          }}
        >
          <Download />
        </IconButton>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Grid2 container>
        <Grid2 item xs={6}>
          <Box sx={{ mt: 2 }} display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="body2" color="textSecondary">
              Plot No:
            </Typography>
            <Typography variant="body1">
              {parcelData?.LandParcel?.plotNo}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }} display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="body2" color="textSecondary">
              Fr No:
            </Typography>
            <Typography variant="body1">
              {parcelData?.LandParcel?.frNo}
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }} display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="body2" color="textSecondary">
              Owner Name:
            </Typography>
            <Typography variant="body1">
              {parcelData?.Ownership?.name}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }} display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="body2" color="textSecondary">
              Owner Phone:
            </Typography>
            <Typography variant="body1">
              {parcelData?.Ownership?.phoneNumber}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }} display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="body2" color="textSecondary">
              Sub County:
            </Typography>
            <Typography variant="body1">
              {parcelData?.Ownership?.subCounty}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }} display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="body2" color="textSecondary">
              Ward:
            </Typography>
            <Typography variant="body1">
              {parcelData?.Ownership?.ward}
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
      <SingleParcel
        key={parcelData.id}
        geom={parcelData?.LandParcel?.geom}
        area={parcelData?.LandParcel?.area}
      />
    </Box>
  );
}
