import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SingleParcel from "./SingleParcel";
import MapIcon from "@mui/icons-material/Map";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";

export default function List() {
  const [data, setData] = useState({ rows: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [column, setColumn] = useState("plotNo");
  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showMap, setShowMap] = useState({});

  useEffect(() => {
    if (searchValue === "") {
      fetchLandParcels();
    }
  }, [paginationModel.page, paginationModel.pageSize, searchValue]);

  const fetchLandParcels = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `/api/landparcels?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}&includeAdminUnits=true`
      );
      const fetchedData = await response.json();
      if (response.ok) {
        const enhancedData = await enhanceData(fetchedData.data);
        setData({ rows: enhancedData, total: fetchedData.total });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const enhanceData = async (rowData) => {
    try {
      return await Promise.all(
        rowData.map(async (item) => {
          try {
            const response = await fetch(`/api/parcellink?parcelId=${item.id}`);
            if (!response.ok) {
              throw new Error(`Failed to fetch parcel links for id ${item.id}`);
            }
            const enhancedData = await response.json();
            return { ...item, ...enhancedData };
          } catch (error) {
            console.error(`Error fetching data for parcel ${item.id}:`, error);
            return { ...item }; // Return original item if enhancement fails
          }
        })
      );
    } catch (error) {
      console.error("Error enhancing data:", error);
      return rowData;
    }
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (value !== "") {
      try {
        setIsLoading(true);
        const response = await fetch(
          `/api/landparcels?offset=0&limit=${paginationModel.pageSize}&${column}=${value}&includeAdminUnits=true`
        );
        const fetchedData = await response.json();
        if (response.ok) {
          const enhancedData = await enhanceData(fetchedData.data);
          setData({ rows: enhancedData, total: fetchedData.total });
        }
      } catch (error) {
        console.error("Error searching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRowClick = (params) => {
    setSelectedParcel(params?.row);
    setOpenDialog(true);
  };

  const toggleMap = (id) => {
    setShowMap((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const columns = [
    {
      headerName: "No",
      renderCell: (params) => {
        const index = data
          ? data?.rows?.map((e) => e.id).indexOf(params?.row?.id)
          : 0;
        return (
          <Box display="flex" alignItems="center" height="100%">
            <Chip
              label={
                index + 1 + paginationModel.page * paginationModel.pageSize
              }
            />
          </Box>
        );
      },
    },
    {
      field: "geom",
      headerName: "Map",
      width: 150,
      renderCell: (params) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          py={1}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toggleMap(params.row.id);
            }}
          >
            <MapIcon color={showMap[params.row.id] ? "primary" : "inherit"} />
          </IconButton>
          {showMap[params.row.id] && (
            <SingleParcel geom={params?.value} area={params?.row?.area} />
          )}
        </Box>
      ),
    },
    {
      field: "plotNo",
      headerName: "Plot No",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "lrNo",
      headerName: "LR No",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Boundary",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "area",
      headerName: "Area",
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.value}
        </Box>
      ),
    },
    {
      field: "AdminUnit.subCounty",
      headerName: "SubCounty",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.row.AdminUnit?.subCounty || "-"}
        </Box>
      ),
    },
    {
      field: "AdminUnit.ward",
      headerName: "Ward",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.row.AdminUnit?.ward || "-"}
        </Box>
      ),
    },
    {
      field: "AdminUnit.name",
      headerName: "Town",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          {params.row.AdminUnit?.name || "-"}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setSelectedParcel(params.row);
            setOpenDialog(true);
          }}
        >
          <InfoIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Box display="flex" gap={2}>
        <Typography variant="h6" color="primary.light">
          Parcels List
        </Typography>
        <Box flexGrow={1}></Box>
        <FormControl sx={{ minWidth: { xs: "100%", md: "150px" } }}>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
          >
            <MenuItem value="plotNo">Plot Number</MenuItem>
            <MenuItem value="frNo">FR Number</MenuItem>
            <MenuItem value="lrNo">LR Number</MenuItem>
            <MenuItem value="area">Area</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          sx={{ minWidth: { xs: "100%", md: "150px" } }}
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <DataGrid
          rows={data.rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          rowCount={data.total}
          paginationMode="server"
          loading={isLoading}
          disableSelectionOnClick
          onRowClick={handleRowClick}
          autoHeight
          getRowHeight={() => "auto"}
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Parcel Details</DialogTitle>
        <DialogContent>
          {selectedParcel && (
            <>
              <Typography variant="h6" gutterBottom>
                Map
              </Typography>
              <Divider sx={{ my: 1 }} />
              <SingleParcel
                geom={selectedParcel.geom}
                area={selectedParcel.area}
                height={200}
                width={300}
              />
              <br />
              <Typography variant="h6" gutterBottom>
                Details
              </Typography>
              <Divider sx={{ my: 1 }} />
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" sx={{ p: 1 }}>
                        Attribute
                      </TableCell>
                      <TableCell padding="none" sx={{ p: 1 }}>
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(selectedParcel).map(([key, value]) => {
                      if (
                        key !== "geom" &&
                        key !== "AdminUnit" &&
                        typeof value !== "object" &&
                        key !== "id" &&
                        key !== "adminUnitId"
                      ) {
                        if (key === "createdAt" || key === "updatedAt") {
                          return (
                            <TableRow key={key}>
                              <TableCell padding="none" sx={{ p: 1 }}>
                                {key}
                              </TableCell>
                              <TableCell padding="none" sx={{ p: 1 }}>
                                {dayjs(value).format("DD/MM/YYYY HH:mm")}
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return (
                          <TableRow key={key}>
                            <TableCell padding="none" sx={{ p: 1 }}>
                              {key}
                            </TableCell>
                            <TableCell padding="none" sx={{ p: 1 }}>
                              {value}
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {selectedParcel.AdminUnit && (
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Admin Unit
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell padding="none" sx={{ p: 1 }}>
                            Attribute
                          </TableCell>
                          <TableCell padding="none" sx={{ p: 1 }}>
                            Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(selectedParcel.AdminUnit).map(
                          ([key, value]) => {
                            if (!key.includes("id")) {
                              if (key === "createdAt" || key === "updatedAt") {
                                return (
                                  <TableRow key={key}>
                                    <TableCell padding="none" sx={{ p: 1 }}>
                                      {key}
                                    </TableCell>
                                    <TableCell padding="none" sx={{ p: 1 }}>
                                      {dayjs(value).format("DD/MM/YYYY HH:mm")}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return (
                                <TableRow key={key}>
                                  <TableCell padding="none" sx={{ p: 1 }}>
                                    {key}
                                  </TableCell>
                                  <TableCell padding="none" sx={{ p: 1 }}>
                                    {value}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
