import React, { useEffect, useMemo, useState } from "react";
import {
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Divider,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { BarChart, PieChart } from "@mui/x-charts";
import "../App.css";
import { countyData } from "../assets/data/data";
import LandParcelsChart from "../components/Home/LandParcelsChart";
import Map from "../components/Home/Map";

// const formatChartData = (subCountyAggregates) => {
//   return subCountyAggregates.map((subCounty) => ({
//     subCounty: subCounty.subCounty,
//     totalRevenue: subCounty.totalRevenue,
//     totalBilledAmount: subCounty.totalBilledAmount,
//     totalArrears: subCounty.totalArrears,
//   }));
// };

export default function Home() {
  const [loading, setLoading] = useState(true);
  const subCountyKeys = getSubCountyKeys(countyData);
  const [subcounties, setSubCounties] = useState(subCountyKeys);
  const [wards, setWards] = useState(
    getWardsBySubCounty(subcounties[0], countyData)
  );
  const today = new Date();
  const [start, setStart] = useState(`${today.getFullYear()}-01-01`);
  const [end, setEnd] = useState(today.toISOString().split("T")[0]);
  const [dloading, setDLoading] = useState(false);
  const [subCountyData, setSubCountyData] = useState([]);
  const [wardData, setWardData] = useState([]);
  const [townData, setTownData] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("town");
  const [error, setError] = useState(null);
  const [genderData, setGenderData] = useState([]);
  const [tenure, setTenure] = useState([]);
  const [landRatesRentData, setLandRatesRentData] = useState([]);

  const [data, setData] = useState({
    countLandParcels: 0,
    countValuationRoll: 0,
    countTowns: 0,
    searchCount: 0,
    totalRevenue: 0,
    totalBilledAmount: 0,
    totalArrears: 0,
    unitParcels: 0,
  });

  const endpoints = [
    { url: "/api/landparcels", key: "countLandParcels" },
    { url: "/api/valuations", key: "countValuationRoll" },
    { url: "/api/adminunits", key: "countTowns" },
    { url: "/api/search", key: "searchCount" },
    {
      url: "/api/billings",
      keys: ["totalRevenue", "totalBilledAmount", "totalArrears"],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await Promise.all(
          endpoints.map(async ({ url, key, keys }) => {
            const response = await fetch(url);
            const result = await response.json();

            // Check if multiple keys are needed for a single endpoint
            if (keys) {
              // Return each key with its corresponding data
              return keys.reduce((acc, itemKey) => {
                acc[itemKey] = result[itemKey] || 0;
                return acc;
              }, {});
            } else {
              // Single key handling
              return { [key]: result.total || 0 };
            }
          })
        );

        // Merge all results into one object
        const newData = results.reduce((acc, result) => {
          return { ...acc, ...result };
        }, {});

        setData((prevData) => ({ ...prevData, ...newData }));
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/billings/location/charts");
        const data = await response.json();

        // Process data for subcounty, ward, and town charts
        const subCountyData = data.subCountyAggregates.map((item) => ({
          label: item.subCounty,
          totalBilledAmount: item.totalBilledAmount,
          totalRevenue: item.totalRevenue,
          totalArrears: item.totalArrears,
        }));

        const wardData = data.wardAggregates.map((item) => ({
          label: item.ward,
          totalBilledAmount: item.totalBilledAmount,
          totalRevenue: item.totalRevenue,
          totalArrears: item.totalArrears,
        }));

        const townData = data.townAggregates.map((item) => ({
          label: item.adminUnitName,
          totalLandRent: item.totalLandRent || 0,
          totalLandRate: item.totalLandRate || 0,
          totalBilledAmount: item.totalBilledAmount || 0,
          totalRevenue: item.totalRevenue || 0,
        }));

        // Format chart data if needed
        // const formattedData = formatChartData(data.subCountyAggregates);

        setSubCountyData(subCountyData);
        setWardData(wardData);
        setTownData(townData);
        // setStacked(formattedData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Memoized data map to optimize performance
  const dataMap = useMemo(
    () => ({
      subCounty: {
        xAxis: subCountyData.map((item) => item.label),
        totalLandRentData: subCountyData.map((item) => item.totalLandRent || 0),
        totalLandRateData: subCountyData.map((item) => item.totalLandRate || 0),
        totalBilledAmountData: subCountyData.map(
          (item) => item.totalBilledAmount || 0
        ),
        totalRevenueData: subCountyData.map((item) => item.totalRevenue || 0),
      },
      ward: {
        xAxis: wardData.map((item) => item.label),
        totalLandRentData: wardData.map((item) => item.totalLandRent || 0),
        totalLandRateData: wardData.map((item) => item.totalLandRate || 0),
        totalBilledAmountData: wardData.map(
          (item) => item.totalBilledAmount || 0
        ),
        totalRevenueData: wardData.map((item) => item.totalRevenue || 0),
      },
      town: {
        xAxis: townData.map((item) => item.label),
        totalLandRentData: townData.map((item) => item.totalLandRent || 0),
        totalLandRateData: townData.map((item) => item.totalLandRate || 0),
        totalBilledAmountData: townData.map(
          (item) => item.totalBilledAmount || 0
        ),
        totalRevenueData: townData.map((item) => item.totalRevenue || 0),
      },
    }),
    [subCountyData, wardData, townData]
  );

  // Extract data for the selected level
  const { xAxis, totalBilledAmountData, totalRevenueData } =
    dataMap[selectedLevel];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use Promise.all to fetch both endpoints in parallel
        const [ownershipResponse, valuationsResponse] = await Promise.all([
          fetch("/api/ownerships"),
          fetch("/api/valuations"),
        ]);

        // Parse the responses as JSON
        const ownershipData = await ownershipResponse.json();
        const valuationsData = await valuationsResponse.json();

        // Prepare gender data
        const maleCount = parseInt(ownershipData.genderCounts.maleCount, 10);
        const femaleCount = parseInt(
          ownershipData.genderCounts.femaleCount,
          10
        );
        setGenderData([
          { id: 0, value: maleCount, label: "Male" },
          { id: 1, value: femaleCount, label: "Female" },
        ]);

        // Prepare tenure data
        const freeholdCount = parseInt(valuationsData.tenureCount.freehold, 10);
        const leaseholdCount = parseInt(
          valuationsData.tenureCount.leasehold,
          10
        );
        setTenure([
          { id: 0, value: freeholdCount, label: "Freehold" },
          { id: 1, value: leaseholdCount, label: "Leasehold" },
        ]);

        // Prepare land rates and land rent data
        const totalLandRates = parseInt(valuationsData.totalLandRates, 10);
        const totalLandRent = parseInt(valuationsData.totalLandRent, 10);
        setLandRatesRentData([
          { id: 0, value: totalLandRates, label: "Land Rates" },
          { id: 1, value: totalLandRent, label: "Land Rent" },
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function getWardsBySubCounty(subCountyKey, data) {
    const subCounty = data.find((item) => item[subCountyKey]);
    return subCounty ? subCounty[subCountyKey] : [];
  }

  function getSubCountyKeys(data) {
    return data.map((subCounty) => Object.keys(subCounty)[0]);
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, pt: 1 }}>
        <Typography fontWeight="bold" color="primary" variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Box flexGrow={1}></Box>
        <TextField
          size="small"
          onChange={(e) => setStart(e.target.value)}
          value={start}
          label="Start"
          type="date"
        />
        <TextField
          size="small"
          onChange={(e) => setEnd(e.target.value)}
          value={end}
          label="End"
          type="date"
        />
      </Box>
      <Box sx={{ display: "grid", placeContent: "center", height: "35px" }}>
        {dloading && (
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
          </div>
        )}
      </Box>
      <Grid2 container spacing={3}>
        <CardItem title="Land Parcels" value={data.countLandParcels} />
        <CardItem title="Valuation Roll" value={data.countValuationRoll} />
        <CardItem title="Towns" value={data.countTowns} />
        <CardItem title="Searches" value={data.searchCount} />
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Ownership</Typography>
              <PieChart
                margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
                colors={["#4F75FF", "#7E60BF"]}
                series={[
                  {
                    data: genderData,
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    outerRadius: 70,
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    itemMarkWidth: 16,
                    itemMarkHeight: 16,
                    padding: 0,
                    labelStyle: {
                      fontSize: 12,
                    },
                  },
                }}
                height={200}
              />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Tenure</Typography>
              <PieChart
                margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
                colors={["#5B99C2", "#FFDA76"]}
                series={[
                  {
                    data: tenure,

                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    outerRadius: 70,
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    itemMarkWidth: 16,
                    itemMarkHeight: 16,
                    padding: 0,
                    labelStyle: {
                      fontSize: 12,
                    },
                  },
                }}
                height={200}
              />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Developed</Typography>
              <PieChart
                margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
                colors={["#6A9C89", "#A04747"]}
                series={[
                  {
                    data: [
                      { id: 0, value: 1045, label: "Yes" },
                      { id: 1, value: 1450, label: "No" },
                    ],
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    outerRadius: 70,
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    itemMarkWidth: 16,
                    itemMarkHeight: 16,
                    padding: 0,
                    labelStyle: {
                      fontSize: 12,
                    },
                  },
                }}
                height={200}
              />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Fee Type</Typography>
              <PieChart
                margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
                colors={["#F05A7E", "#E85C0D"]}
                series={[
                  {
                    data: landRatesRentData,
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    outerRadius: 70,
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    itemMarkWidth: 16,
                    itemMarkHeight: 16,
                    padding: 0,
                    labelStyle: {
                      fontSize: 12,
                    },
                  },
                }}
                height={200}
              />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="title" fontWeight="600">
                  Revenue Collection
                </Typography>
                <Box>
                  <Select
                    value={selectedLevel}
                    onChange={(e) => setSelectedLevel(e.target.value)}
                    label="Sub County"
                    size="small"
                    sx={{ minWidth: "120px" }}
                  >
                    <MenuItem value="subCounty">Subcounty</MenuItem>
                    <MenuItem value="ward">Ward</MenuItem>
                    <MenuItem value="town">Town</MenuItem>
                  </Select>
                </Box>
              </Box>
              <BarChart
                xAxis={[
                  {
                    data: xAxis,
                    scaleType: "band",
                  },
                ]}
                yAxis={[
                  {
                    valueFormatter: (value) => {
                      if (value >= 1000000) {
                        return `${(value / 1000000).toFixed(0)}m`;
                      } else if (value >= 1000) {
                        return `${(value / 1000).toFixed(0)}k`;
                      }
                      return value;
                    },
                  },
                ]}
                series={[
                  {
                    data: totalRevenueData,
                    color: "#0F67B9",
                  },
                  {
                    data: totalBilledAmountData,
                    color: "orange",
                  },
                ]}
                slotProps={{
                  legend: {
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    labelStyle: {
                      fontSize: 12,
                      fill: "primary.main",
                    },
                  },
                }}
                height={300}
              />
              <Box display="flex" justifyContent="center" gap={4} mt={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: "primary.main",
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <span>Expected</span>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: "orange",
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <span>Paid</span>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 container spacing={3} sx={{ width: "100%" }}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Card
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 10px 30px #60606040",
              }}
            >
              <LandParcelsChart />
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Box sx={{ width: "100%" }}>
              <Map />
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = (props) => {
  return (
    <Grid2 size={{ md: 3, xs: 12, sm: 2 }}>
      <Card
        sx={{
          borderRadius: "12px",
          boxShadow: "0px 10px 30px #60606040",
        }}
      >
        <CardContent>
          <Typography variant="subtitle" gutterBottom>
            {props.title}
          </Typography>
          <Typography color="primary" variant="h5" mt={2}>
            {props?.value?.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </CardContent>
      </Card>
    </Grid2>
  );
};

const RevenueItem = (props) => {
  return (
    <Card
      sx={{
        borderRadius: "12px",
        position: "relative",
        boxShadow: "0px 10px 30px #60606040",
      }}
    >
      <CardContent>
        <Box gap={2} display="flex" mb={2}>
          <Box
            sx={{
              height: "32px",
              width: "32px",
              backgroundColor: "orange",
              borderRadius: "50%",
              display: "grid",
              placeContent: "center",
              mb: 1,
            }}
          >
            {props.icon}
          </Box>
          <Typography color="primary" variant="h6">
            KSh{" "}
            {props.value.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </Box>
        <Typography
          sx={{
            position: "absolute",
            right: "1em",
            bottom: "1em",
            fontSize: "small",
          }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            position: "absolute",
            left: "1em",
            bottom: "1em",
            fontSize: "small",
          }}
          variant="subtitle"
          gutterBottom
        >
          {props.year}
        </Typography>
      </CardContent>
    </Card>
  );
};
