import React from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";

export default function NewRecord() {
  return (
    <Box>
      <Typography variant="h6" color="primary.light">
        New Record
      </Typography>
      <Divider />
      <Typography mt={1} variant="body1" color="primary.light">
        Owner Information
      </Typography>
      <Divider
        mt={1}
        sx={{
          backgroundColor: "secondary.main",
          height: 2,
          width: "100%",
        }}
      />
      <Box display={"flex"} gap={2}>
        <TextField
          label="Owner Name"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
        <TextField
          label="Mobile Number"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Email (optional)"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <FormControl
          fullWidth
          variant="outlined"
          margin="dense"
          size="small"
          required
        >
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select labelId="gender-label" label="gender" defaultValue={""}>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Male">Male</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box display={"flex"} gap={2}>
        <FormControl
          fullWidth
          variant="outlined"
          margin="dense"
          size="small"
          required
        >
          <InputLabel id="idtype-label">ID Type</InputLabel>
          <Select labelId="idtype-label" label="idtype" defaultValue={""}>
            <MenuItem value="NationalID">National ID</MenuItem>
            <MenuItem value="Passport">Passport</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="ID/Passport Number"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="KRA Pin Number"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
        <TextField
          label="Co-Owners (optional)"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
      </Box>
      <TextField
        label="Next of Kin (optional)"
        variant="outlined"
        size="small"
        margin="dense"
        fullWidth
      />
      <Typography mt={1} variant="body1" color="primary.light">
        Plot Details
      </Typography>
      <Divider
        mt={1}
        sx={{
          backgroundColor: "secondary.main",
          height: 2,
          width: "100%",
        }}
      />
      <Box display={"flex"} gap={2}>
        <FormControl
          fullWidth
          variant="outlined"
          margin="dense"
          size="small"
          required
        >
          <InputLabel id="tenure-label">Tenure</InputLabel>
          <Select labelId="tenure-label" label="tenure" defaultValue={""}>
            <MenuItem value="FreeHold">Free Hold</MenuItem>
            <MenuItem value="LeaseHold">LeaseHold</MenuItem>
            <MenuItem value="PublicLand">Public Land</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Area Ha"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Length (Ft)"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
        <TextField
          label="Width (Ft)"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          required
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Site Value (KSh)"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Land Rate (KSh)"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Accumulated Rates (Optional) "
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Sub County"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Ward"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Latitude (decimal degrees)"
          variant="outlined"
          size="small"
          margin="dense"
          type="number"
          fullWidth
        />
        <TextField
          label="Longitude (decimal degrees)"
          variant="outlined"
          size="small"
          type="number"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Market ID"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Market Name"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Typography mt={1} variant="body1" color="primary.light">
        Other Details (Optional)
      </Typography>
      <Divider
        mt={1}
        sx={{
          backgroundColor: "secondary.main",
          height: 2,
          width: "100%",
        }}
      />
      <Box display={"flex"} gap={2}>
        <TextField
          label="LR Number"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Block Number"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Physical Address"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Postal Address Town"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Postal Address"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Postal Code"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Zone"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Physical Address"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Rateable Value"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Land Rates Arrears"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Rent Payable"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <TextField
          label="Rent arrears"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Penalty"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
        <TextField
          label="Accumulated Penalties"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <Box display={"flex"} gap={2}>
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel id="ownership-label">Type of Ownership</InputLabel>
          <Select labelId="ownership-label" label="ownership" defaultValue={""}>
            <MenuItem value="Individual">Individual</MenuItem>
            <MenuItem value="Group">Group</MenuItem>
            <MenuItem value="PrivateInstitution">Private Institution</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel id="acquisition-label">Mode of acquisition</InputLabel>
          <Select
            labelId="acquisition-label"
            label="acquisition"
            defaultValue={""}
          >
            <MenuItem value="Purchased">Purchased</MenuItem>
            <MenuItem value="Allotment">Allotment</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel id="disputed-label">Disputed?</InputLabel>
          <Select labelId="disputed-label" label="disputed" defaultValue={""}>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TextField
        label="Nature of Dispute"
        variant="outlined"
        size="small"
        margin="dense"
        fullWidth
      />
      <TextField
        label="Property use Description"
        variant="outlined"
        size="small"
        margin="dense"
        fullWidth
      />
      <Box display={"flex"} gap={2}>
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel id="developed-label">Developed?</InputLabel>
          <Select labelId="developed-label" label="developed" defaultValue={""}>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" margin="dense" size="small">
          <InputLabel id="development-label">Development Approved?</InputLabel>
          <Select
            labelId="development-label"
            label="development"
            defaultValue={""}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Main Structure"
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
        />
      </Box>
      <TextField
        label="Remarks"
        variant="outlined"
        multiline={true}
        rows={4}
        size="small"
        margin="dense"
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{ textAlign: "center" }}
      >
        Submit
      </Button>
    </Box>
  );
};
