import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Alert,
  Snackbar,
  Divider,
  Box,
  Stack,
  CardContent,
  Collapse,
  Chip,
  Grid2,
} from "@mui/material";
import SingleParcel from "../../LandInfo/LandParcels/SingleParcel";
import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import { ArrowForwardIos } from "@mui/icons-material";

export default function Plots({ user }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedLoanId, setExpandedLoanId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const fetchData = async () => {
    setLoading(true);
    try {
      const offset = (currentPage - 1) * limit;
      const response = await fetch(
        `/api/parcellink?ownershipId=${user?.id}&offset=${offset}&limit=${limit}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch payments");
      const data = await response.json();
      console.log(data);
      setData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user?.id, currentPage]);

  const handleExpandClick = (loanId) => {
    setExpandedLoanId((prevId) => (prevId === loanId ? null : loanId));
  };

  return (
    <Box pb={2}>
      <Stack spacing={2}>
        {data?.data?.map((link) => (
          <Card
            key={link.id}
            sx={{
              position: "relative",
              boxShadow: "0px 4px 16px #60606040",
              borderRadius: "8px",
            }}
          >
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Plot Number: {link.landparcels?.plotNo}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong style={{ fontWeight: 500 }}>Start Date:</strong>{" "}
                {new Date(link.startDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong style={{ fontWeight: 500 }}>End Date:</strong>{" "}
                {link.endDate
                  ? new Date(link.endDate).toLocaleDateString()
                  : "Ongoing"}
              </Typography>
              <Chip
                label={link.isActive ? "Active" : "Inactive"}
                sx={{ position: "absolute", top: "1em", right: "1em" }}
                color={link.isActive ? "success" : "default"}
              />
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="outlined"
                size="small"
                onClick={() => handleExpandClick(link.id)}
              >
                {expandedLoanId === link.id ? (
                  <ArrowForwardIos
                    sx={{ rotate: "-90deg", fontSize: "small", mr: 1 }}
                  />
                ) : (
                  <ArrowForwardIos
                    sx={{ rotate: "90deg", fontSize: "small", mr: 1 }}
                  />
                )}{" "}
                {expandedLoanId === link.id ? "Hide Details" : "View Details"}
              </Button>
            </CardContent>
            <Collapse
              in={expandedLoanId === link.id}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                {/* Land Parcel Details Section */}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Land Parcel Details
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Grid2 container spacing={3}>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Plot Number:</strong>{" "}
                      {link.landparcels?.plotNo || "N/A"}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>FR Number:</strong>{" "}
                      {link.landparcels?.frNo || "N/A"}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong style={{ fontWeight: 500 }}>Area:</strong>{" "}
                      {link.landparcels?.area} hectares
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <SingleParcel
                      geom={link.landparcels?.geom}
                      area={link.landparcels?.area}
                    />
                  </Grid2>
                </Grid2>
                {/* Owner Details Section */}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Owner Details
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontWeight: 500 }}>Gender:</strong>{" "}
                  {link.owners?.gender}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontWeight: 500 }}>Ownership Type:</strong>{" "}
                  {link.owners?.ownershipType}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontWeight: 500 }}>County:</strong>{" "}
                  {link.owners?.county}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontWeight: 500 }}>Physical Address:</strong>{" "}
                  {link.owners?.physicalAddress}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong style={{ fontWeight: 500 }}>Email:</strong>{" "}
                  {link.owners?.email}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        ))}

        <Typography variant="subtitle">Danger Zone</Typography>
        <Divider />
        <SearchLandParcel user={user} />
      </Stack>
    </Box>
  );
}

const SearchLandParcel = ({ user }) => {
  const [searchValue, setSearchValue] = useState("");
  const [parcels, setParcels] = useState([]);
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [expandedId, setExpandedLoanId] = useState(null);

  const handleExpandClick = (loanId) => {
    setExpandedLoanId((prevId) => (prevId === loanId ? null : loanId));
  };

  // Search for land parcels by plot number
  const handleSearch = async () => {
    if (!searchValue.trim()) return;
    setLoading(true);
    try {
      const response = await fetch(
        `/api/landparcels?offset=0&limit=1&plotNo=${searchValue}&includeValuations=true&includeAdminUnits=true`
      );
      const data = await response.json();
      if (response.ok) {
        setParcels(data?.data || []);
      } else {
        setError(data?.message || "Error fetching land parcels");
      }
    } catch (error) {
      setError("Error fetching land parcels. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const checkExistingOwner = async (parcel) => {
    try {
      setSelectedParcel(parcel);

      // Check for existing links
      const response = await fetch(`/api/parcellink?landParcelId=${parcel.id}`);
      const data = await response.json();

      if (response.ok && data?.data?.length > 0) {
        setOwners(data?.data[0].owners);
      }
      setConfirmationOpen(true);
    } catch (error) {}
  };

  // Handle linking a land parcel
  const handleLinkParcel = async (replaceExisting) => {
    if (!selectedParcel) return;

    setConfirmationLoading(true);
    try {
      // Delete existing record if replacing
      if (replaceExisting) {
        await fetch(`/api/parcellink/${selectedParcel.id}`, {
          method: "DELETE",
        });
      }

      // Create new link
      const response = await fetch("/api/parcellink", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          landParcelId: selectedParcel.id,
          ownershipId: user?.id,
          phoneNumber: user?.phoneNumber,
          nationalId: user?.nationalId,
          plotNo: selectedParcel.plotNo,
          startDate: new Date().toISOString(),
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setError("Land parcel linked successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError(data?.message || "Error linking land parcel.");
      }
    } catch (error) {
      setError("Error linking land parcel. Please try again.");
    } finally {
      setConfirmationLoading(false);
      setConfirmationOpen(false);
      setSelectedParcel(null);
    }
  };

  return (
    <>
      <Card
        sx={{
          p: 3,
          boxShadow: "0px 4px 8px #60606040",
          borderRadius: 3,
          position: "relative",
        }}
      >
        <Typography
          variant="title"
          color="primary"
          sx={{ display: "block" }}
          gutterBottom
        >
          Add a Plot to {user?.name}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
          sx={{ textTransform: "capitalize" }}
        >
          Add Plot
        </Button>
      </Card>

      {/* Search Land Parcels Dialog */}
      <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
        <Box display="flex" gap={1} p={1}>
          <Typography flexGrow={1}>Search Plots</Typography>
          <Button
            variant="outlined"
            onClick={() => setOpenDialog(false)}
            color="secondary"
            size="small"
          >
            Close
          </Button>
        </Box>
        <Divider />
        <DialogContent>
          <TextField
            fullWidth
            label="Enter Plot Number"
            variant="outlined"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={loading}
            sx={{ mb: 2, mx: "auto", display: "block" }}
          >
            {loading ? <CircularProgress size={24} /> : "Search"}
          </Button>
          <List>
            {parcels.map((parcel) => (
              <Card
                key={parcel.id}
                button
                sx={{
                  p: 3,
                  boxShadow: "0px 4px 8px #60606040",
                  borderRadius: 3,
                  position: "relative",
                }}
              >
                <Typography
                  variant="title"
                  gutterBottom
                  display="block"
                >{`Plot No: ${parcel?.plotNo}`}</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography
                  variant="title"
                  gutterBottom
                  display="block"
                >{`LR No: ${parcel?.valuation?.lrNo}`}</Typography>
                <Typography
                  variant="title"
                  display="block"
                  gutterBottom
                >{`Old LR No: ${parcel?.valuation?.oldLrNo}`}</Typography>
                <Typography variant="body2" gutterBottom>{`CGH: KSh ${(
                  parcel?.valuation?.cgh - 0
                ).toLocaleString()}`}</Typography>
                <Typography variant="body2" gutterBottom>{`Private: KSh ${(
                  parcel?.valuation?.private - 0
                ).toLocaleString()}`}</Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                >{`Area: ${parcel?.valuation?.size} Ha`}</Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                >{`Tenure: ${parcel?.valuation?.tenure}`}</Typography>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "capitalize", mt: 1 }}
                  onClick={() => handleExpandClick(parcel?.id)}
                >
                  {expandedId === parcel?.id ? "Hide Details" : "View Details"}
                </Button>
                <Chip
                  sx={{ position: "absolute", right: "1em", top: "1em" }}
                  label={parcel?.area + " Ha"}
                />
                <Collapse
                  in={expandedId === parcel.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      Situation
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography
                      variant="body2"
                      gutterBottom
                    >{`Sub County: ${parcel?.AdminUnit?.subCounty}`}</Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                    >{`Ward: ${parcel?.AdminUnit?.ward}`}</Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                    >{`Town/Market: ${parcel?.AdminUnit?.name}`}</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Button
                      onClick={() => {
                        checkExistingOwner(parcel);
                      }}
                      color="warning"
                      variant="contained"
                      sx={{
                        display: "block",
                        mx: "auto",
                        textTransform: "capitalize",
                        mt: 2,
                      }}
                    >
                      Check Existing Owners & Add
                    </Button>
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </List>
          {error && <Alert severity="warning">{error}</Alert>}
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        fullWidth
      >
        <Box display="flex" gap={1} p={1}>
          <Typography variant="title" flexGrow={1}>
            Confirm Action
          </Typography>{" "}
          <Button
            onClick={() => setConfirmationOpen(false)}
            color="secondary"
            variant="outlined"
            disabled={confirmationLoading}
          >
            Cancel
          </Button>
        </Box>
        <Divider />
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to link this plot to {user?.name}?
          </Typography>
          <Alert sx={{ mt: 2 }} severity="info">
            {Array.isArray(owners)
              ? owners.length > 0
                ? `This plot belongs to ${owners
                    .map((owner) => owner.name)
                    .join(", ")}`
                : "This plot has no registered owner!!"
              : owners?.name
              ? `This plot belongs to ${owners.name} - ${owners.phoneNumber}`
              : "This plot has no registered owner!!"}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleLinkParcel(false)}
            color="primary"
            disabled={confirmationLoading}
            variant="outlined"
          >
            {confirmationLoading ? "Processing..." : "Add as Owner"}
          </Button>
          {((Array.isArray(owners) && owners.length > 0) || (owners && typeof owners === 'object')) && (
            <Button
              onClick={() => handleLinkParcel(true)}
              color="warning"
              variant="outlined"
              disabled={confirmationLoading}
            >
              {confirmationLoading ? "Processing..." : "Replace Owner"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback */}
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        onClose={() => setError("")}
      >
        <Alert severity="info">{error}</Alert>
      </Snackbar>
    </>
  );
};
