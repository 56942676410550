import React, { useState } from "react";
import { Box, ButtonGroup, Button, Card } from "@mui/material";
import FirstApproval from "./FirstApproval";
import NewRecord from "./NewRecord";
import SecondApproval from "./SecondApproval";

export default function Data() {
  const [selected, setSelected] = useState("First Approval");

  const handleNavigation = (path) => {
    setSelected(path);
  };

  return (
    <Box>
      <ButtonGroup aria-label="outlined primary button group">
        <Button
          variant={selected == "First Approval" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("First Approval")}
        >
          First Approval
        </Button>
        <Button
          variant={selected == "Second Approval" ? "contained" : "outlined"}
          sx={{ textTransform: "capitalize" }}
          onClick={() => handleNavigation("Second Approval")}
        >
          Second Approval
        </Button>
      </ButtonGroup>
      <Box
        sx={{
          padding: "16px",
          boxShadow: "0px 4px 20px #60606030",
        }}
        component={Card}
      >
        {selected == "First Approval" && <FirstApproval />}
        {selected == "Second Approval" && <SecondApproval />}
        {selected == "New Record" && <NewRecord />}
      </Box>
    </Box>
  );
}
