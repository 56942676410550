import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Navbar from "./Navbar";
import Home from "../Pages/Home";
import BillingDashboard from "../Sections/Billing/BillingDashboard";
import Payments from "../Sections/Billing/Payments";
import Customers from "../Sections/Billing/Customers";
import BillingMap from "../Sections/Billing/BillingMap";
import Settings from "../Pages/Settings";
import UsersHome from "./Users/UsersHome";
import NotFound from "../Pages/NotFound";
import Data from "./Data/DataCollection/Data";
import DataLandParcels from "./Data/DataLandParcels";
import DataValuations from "./Data/DataValuationRoll";
import DataOwnerships from "./Data/DataLandOwners";
import DataAdminUnits from "./Data/DataLandAdminUnits";
import ParcelsInfo from "./LandInfo/LandParcels/ParcelsInfo";
import ParcelsMap from "./LandInfo/LandParcels/ParcelsMap";
import ValuationRoll from "./LandInfo/ValuationRoll/ValuationRoll";
import ParcelSearch from "./LandInfo/ParcelSearch/ParcelSearch";
import ParcelDetailPage from "./LandInfo/ParcelSearch/ParcelDetailPage";
import OwnerManagement from "./Data/OwnerManagement";
import CSDashboard from "./CustomerSupport/CSDashboard";
import CSEnquiries from "./CustomerSupport/CSEnquiries";
import CSMessages from "./CustomerSupport/CSMessages";
import CSCustomers from "./CustomerSupport/CSCustomers";
import CustomerManagement from "./CustomerSupport/CustomerManagement";
import Transactions from "./LandInfo/LandParcels/Transactions";

function PageRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 9 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Routes>
            <Route
              path="home"
              element={
                user.Department ==
                "Lands, Physical Planning, Housing and Urban Development" ? (
                  <Home />
                ) : user.Department == "Risk Management" ? (
                  <Home /> // Change this to the Risk Management component
                ) : user.Department == "Customer Support" ? (
                  <Home />
                ) : (
                  <Home />
                )
              }
            />
            {/* customer service */}
            <Route path="cs/dashboard" element={<CSDashboard user={user} />} />
            <Route path="cs/issues" element={<CSEnquiries user={user} />} />
            <Route path="cs/activity" element={<CSMessages user={user} />} />
            <Route path="cs/customers" element={<CSCustomers user={user} />} />
            <Route
              path="cs/customers/*"
              element={<CustomerManagement user={user} />}
            />

            <Route path="dc/data" element={<Data user={user} />} />
            <Route path="dc/parcels" element={<DataLandParcels user={user} />} />
            <Route path="dc/valuation" element={<DataValuations user={user} />} />
            <Route path="dc/owners" element={<DataOwnerships user={user} />} />
            <Route path="dc/owners/*" element={<OwnerManagement user={user} />} />
            <Route path="dc/adminunits" element={<DataAdminUnits user={user} />} />

            {/* billing */}
            <Route path="bl/dashboard" element={<BillingDashboard user={user} />} />
            <Route path="bl/payments" element={<Payments user={user} />} />
            <Route path="bl/customers" element={<Customers user={user} />} />
            <Route path="bl/map" element={<BillingMap user={user} />} />
            <Route path="bl/customers/*" element={<CustomerManagement user={user} />} />

            <Route path="li/parcels" element={<ParcelsInfo user={user} />} />
            <Route path="li/parcels/*" element={<ParcelsMap user={user} />} />
            <Route path="li/searches" element={<ParcelSearch user={user} />} />
            <Route path="li/searches/:id" element={<ParcelDetailPage user={user} />} />
            <Route path="li/valuationroll" element={<ValuationRoll user={user} />} />
            <Route path="li/transactions" element={<Transactions user={user} />} />
            

            <Route path="users" element={<UsersHome user={user} />} />
            <Route path="settings" element={<Settings user={user} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Box>
    </Box>
  );
}

export default PageRoutes;
