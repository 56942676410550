import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Modal,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
  Card,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import SingleParcel from "./SingleParcel";
import { Snackbar, Alert } from "@mui/material";
import { Close } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";

const ParcelSearch = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [column, setColumn] = useState("plotNo");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [totalRows, setTotalRows] = useState(0);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSaveSearch = async () => {
    if (!data || !data.data.length) {
      return;
    }
    const searchData = {
      ownershipId: data.data[0].owners?.id,
      landParcelId: data.data[0].landparcels?.id,
      valuationId: data.data[0].landparcels?.valuationId || null,
      parcelOwnershipLinkId: data.data[0].id || null,
      plotNo: data.data[0].plotNo,
      certificate: null,
      startDate: new Date().toISOString(),
      endDate: null,
    };

    try {
      const response = await fetch("/api/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(searchData),
      });

      if (!response.ok) {
        throw new Error("Error saving search: " + response.statusText);
      }

      await response.json();
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);

  const fetchLandParcel = async () => {
    try {
      setData(null);
      setIsLoading(true);

      const response = await fetch(
        `/api/parcellink?${column}=${debouncedSearchValue}&includeAdminUnits=true`
      );
      const result = await response.json();

      if (response.ok) {
        const exactMatches = result.data.filter(
          (item) => item.plotNo === debouncedSearchValue
        );

        setData({ data: exactMatches });
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      setError("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    if (debouncedSearchValue) {
      fetchLandParcel();
    } else {
      setData(null);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (open) {
      setSearchValue("");
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSearchValue("");
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const res = await fetch(
          `/api/search?includeDetails=true&page=${
            paginationModel.page + 1
          }&limit=${paginationModel.pageSize}&search=${searchValue}`,
          {
            method: "get",
          }
        );
        const data = await res.json();

        setSearchData(data?.data || []);
        setTotalRows(data?.total || 0);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [paginationModel.page, paginationModel.pageSize, searchValue]);

  const columns = [
    {
      headerName: "No",
      renderCell: (params) => {
        const index = searchData
          ? searchData?.map((e) => e.id).indexOf(params?.row?.id)
          : 0;
        return (
          <Chip
            label={index + 1 + paginationModel.page * paginationModel.pageSize}
          />
        );
      },
    },
    {
      field: "map",
      headerName: "Map",
      width: 150,
      renderCell: (params) => {
        console.log(params);

        return (
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <SingleParcel
              key={params?.row?.id}
              geom={params?.row?.LandParcel?.geom}
              area={params?.row?.LandParcel?.area}
            />
          </Box>
        );
      },
    },
    {
      field: "plotNo",
      headerName: "Plot No",
      flex: 1,
      valueGetter: (params) => params?.row?.LandParcel?.plotNo || "N/A",
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "ownerName",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) => params?.row?.Ownership?.name || "N/A",
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "ownerPhone",
      headerName: "Phone",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.ParcelOwnershipLink?.phoneNumber || "N/A",
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", gap: 1, alignItems: "center", height: "100%" }}
        >
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params?.row?.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleDelete = (id) => {
    // Implement delete functionality here
    console.log(`Delete item with id: ${id}`);
  };

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: "0px 4px 20px #60606030",
      }}
      component={Card}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="title">Parcel Search</Typography>

        <IconButton
          onClick={handleOpen}
          sx={{
            height: "fit-content",
            px: 3,
            bgcolor: "primary.light",
            borderRadius: 1,
            "&:hover": {
              bgcolor: "primary.main",
            },
          }}
        >
          <Typography
            variant="button"
            sx={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            Create Search
          </Typography>
        </IconButton>
      </Box>

      <Box sx={{ width: "100%", marginTop: 2 }}>
        <DataGrid
          rows={searchData || []}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25]}
          rowCount={totalRows}
          paginationMode="server"
          loading={isLoading}
          disableSelectionOnClick
          onRowClick={(params) => navigate(`/li/searches/${params?.row?.id}`)}
          getRowHeight={() => "auto"}
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parcel-search-modal"
        aria-describedby="enter-plot-number"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            width: "80%",
            maxWidth: 350,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              padding: "4px",
              right: 8,
              color: "gray",
              fontSize: "10px",
              "&:hover": {
                color: "red",
              },
            }}
          >
            <Close />
          </IconButton>

          <Typography
            id="parcel-search-modal"
            variant="h6"
            component="h2"
            sx={{
              mb: 1,
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "0.9rem",
            }}
          >
            Enter Plot Number
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type the parcel plot no"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{
              mb: 1,
              "& .MuiInputBase-root": {
                height: "30px",
              },
              "& .MuiOutlinedInput-root": {
                height: "30px",
              },
            }}
          />

          {isLoading && searchValue.trim() && (
            <Typography sx={{ mt: 1 }}>Loading...</Typography>
          )}

          {error && (
            <Typography sx={{ mt: 2, color: "red" }}>{error}</Typography>
          )}

          {data && (
            <Box sx={{ mt: 1, textAlign: "center" }}>
              {data.data.map((item, index) => (
                <Box key={index} sx={{ mt: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Parcel Details:
                  </Typography>

                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Plot Number:
                  </Typography>
                  <Typography variant="body2">{item.plotNo}</Typography>

                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    FR Number:
                  </Typography>
                  <Typography variant="body2">
                    {item.landparcels?.frNo}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SingleParcel
                      geom={item.landparcels?.geom}
                      area={item.landparcels?.area}
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Owner Details:
                    </Typography>

                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Name:
                    </Typography>
                    <Typography variant="body2">{item.owners?.name}</Typography>

                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Phone Number:
                    </Typography>
                    <Typography variant="body2">
                      {item.owners?.phoneNumber}
                    </Typography>

                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Sub-County:
                    </Typography>
                    <Typography variant="body2">
                      {item.owners?.subCounty}
                    </Typography>

                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Ward:
                    </Typography>
                    <Typography variant="body2">{item.owners?.ward}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              sx={{
                bgcolor: "primary.light",
                "&:hover": {
                  bgcolor: "primary.main",
                },
                color: "white",
                width: "100%",
                borderRadius: 1,
                fontSize: "0.8rem",
              }}
              onClick={handleSaveSearch}
            >
              <Typography variant="button">Save Search</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Search saved successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ParcelSearch;
