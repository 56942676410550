import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Divider,
  TextField,
  Stack,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";
import { CreditCard, MapOutlined } from "@mui/icons-material";
import BarChartIcon from "@mui/icons-material/BarChart";
import MapItem from "./MapItem";

const formatChartData = (subCountyAggregates) => {
  return subCountyAggregates.map((subCounty) => ({
    subCounty: subCounty.subCounty,
    totalRevenue: subCounty.totalRevenue,
    totalBilledAmount: subCounty.totalBilledAmount,
    totalArrears: subCounty.totalArrears,
  }));
};

export default function BillingDashboard() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dloading, setDloading] = useState(false);
  const [start, setStart] = useState("2021-01-01");
  const [end, setEnd] = useState("2021-12-31");
  const [subcounties, setSubcounties] = useState([]);
  const [wards, setWards] = useState([]);
  const [subCountyData, setSubCountyData] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("town");
  const [wardData, setWardData] = useState([]);
  const [townData, setTownData] = useState([]);
  const [landparcels, setLandParcels] = useState(0);
  const [valuations, setValuations] = useState(0);
  const [stacked, setStacked] = useState([]);
  const [error, setError] = useState(null);
  const [mapActive, setMapActive] = useState(true);
  const [barActive, setBarActive] = useState(false);
  const [scLayers, setScLayers] = useState([]);
  const [wardLayers, setWardLayers] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/billings`)
      .then((res) => res.json())
      .then((data) => {
        setStats(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [start, end]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/billings/location/charts");
        const data = await response.json();

        // Process data for subcounty, ward, and town charts
        const subCountyData = data.subCountyAggregates.map((item) => ({
          label: item.subCounty,
          totalBilledAmount: item.totalBilledAmount,
          totalRevenue: item.totalRevenue,
          totalArrears: item.totalArrears,
        }));

        const wardData = data.wardAggregates.map((item) => ({
          label: item.ward,
          totalBilledAmount: item.totalBilledAmount,
          totalRevenue: item.totalRevenue,
          totalArrears: item.totalArrears,
        }));

        const townData = data.townAggregates.map((item) => ({
          label: item.adminUnitName,
          totalLandRent: item.totalLandRent || 0,
          totalLandRate: item.totalLandRate || 0,
          totalBilledAmount: item.totalBilledAmount || 0,
          totalRevenue: item.totalRevenue || 0,
        }));

        // Format chart data if needed
        const formattedData = formatChartData(data.subCountyAggregates);

        setSubCountyData(subCountyData);
        setWardData(wardData);
        setTownData(townData);
        setStacked(formattedData);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Memoized data map to optimize performance
  const dataMap = useMemo(
    () => ({
      subCounty: {
        xAxis: subCountyData.map((item) => item.label),
        totalLandRentData: subCountyData.map((item) => item.totalLandRent || 0),
        totalLandRateData: subCountyData.map((item) => item.totalLandRate || 0),
        totalBilledAmountData: subCountyData.map(
          (item) => item.totalBilledAmount || 0
        ),
        totalRevenueData: subCountyData.map((item) => item.totalRevenue || 0),
      },
      ward: {
        xAxis: wardData.map((item) => item.label),
        totalLandRentData: wardData.map((item) => item.totalLandRent || 0),
        totalLandRateData: wardData.map((item) => item.totalLandRate || 0),
        totalBilledAmountData: wardData.map(
          (item) => item.totalBilledAmount || 0
        ),
        totalRevenueData: wardData.map((item) => item.totalRevenue || 0),
      },
      town: {
        xAxis: townData.map((item) => item.label),
        totalLandRentData: townData.map((item) => item.totalLandRent || 0),
        totalLandRateData: townData.map((item) => item.totalLandRate || 0),
        totalBilledAmountData: townData.map(
          (item) => item.totalBilledAmount || 0
        ),
        totalRevenueData: townData.map((item) => item.totalRevenue || 0),
      },
    }),
    [subCountyData, wardData, townData]
  );

  const {
    xAxis,
    totalLandRentData,
    totalLandRateData,
    totalBilledAmountData,
    totalRevenueData,
  } = dataMap[selectedLevel];

  // Memoized line data
  const lineData = useMemo(
    () => [
      { label: "Land Rent", data: totalLandRentData },
      { label: "Land Rate", data: totalLandRateData },
      { label: "Billed Amount", data: totalBilledAmountData },
      { label: "Revenue", data: totalRevenueData },
    ],
    [
      totalLandRentData,
      totalLandRateData,
      totalBilledAmountData,
      totalRevenueData,
    ]
  );

  useEffect(() => {
    fetch(`/api/landparcels`)
      .then((res) => res.json())
      .then((data) => {
        setLandParcels(data.total);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    fetch(`/api/valuations`)
      .then((res) => res.json())
      .then((data) => {
        setValuations(data.total);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/subcounties`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setScLayers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/wards`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setWardLayers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, pt: 2 }}>
        <Box>
          <Typography
            fontWeight="bold"
            variant="h5"
            sx={{
              background:
                "linear-gradient(109.1deg, rgb(42, 132, 201) 14.6%, rgb(47, 249, 233) 108.2%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Billing Statistics
          </Typography>
        </Box>

        <Box flexGrow={1}></Box>
        <TextField
          size="small"
          onChange={(e) => setStart(e.target.value)}
          value={start}
          label="Start"
          type="date"
        />
        <TextField
          size="small"
          onChange={(e) => setEnd(e.target.value)}
          value={end}
          label="End"
          type="date"
        />
      </Box>
      <Box
        sx={{ display: "grid", placeContent: "center", height: "35px", mb: 2 }}
      >
        {dloading && (
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </Box>
      <Grid2 container spacing={2}>
        <CardItem title="Parcels" value={landparcels || 0} />
        <CardItem title="Total Valuation" value={valuations || 0} />
        <CardItem
          title="Total Billed Amount"
          value={stats?.totalBilledAmount || 0}
        />
        <CardItem title="Total Revenue" value={stats?.totalRevenue || 0} />
        <CardItem title="Total Arrears" value={stats?.totalArrears || 0} />

        <Grid2 size={{ xs: 12, md: 4 }}>
          <Stack spacing={3}>
            <RevenueItem
              title="Expected Revenue"
              year="2023"
              icon={<CreditCard />}
              value={stats?.totalRevenue || 0}
            />
            <RevenueItem
              title="Collected Revenue"
              year="2023"
              icon={<CreditCard />}
              value={stats?.totalBilledAmount || 0}
            />
            <RevenueItem
              title="Arrears + Penalties"
              icon={<CreditCard />}
              value={stats?.totalArrears || 0}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 8 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Box display="flex" justifyContent="flex-end">
                <Select
                  value={selectedLevel}
                  onChange={(e) => setSelectedLevel(e.target.value)}
                  label="Sub County"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  <MenuItem value="subCounty">Subcounty</MenuItem>
                  <MenuItem value="ward">Ward</MenuItem>
                  <MenuItem value="town">Town</MenuItem>
                </Select>
              </Box>
              <LineChart
                height={260}
                series={lineData}
                xAxis={[{ scaleType: "point", data: xAxis }]}
                slotProps={{
                  xAxis: { tick: { fontSize: 12 } },
                  yAxis: { tick: { fontSize: 12 } },
                  legend: { vertical: "bottom", horizontal: "middle" },
                }}
              />
              {/* <Legend series={lineData} iconSize={12} /> */}
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 mt={4} size={{ xs: 12 }}>
          <Box gap={1} display="flex" flexWrap="wrap">
            <Button
              variant={mapActive ? "contained" : "outlined"}
              onClick={() => {
                setMapActive(true);
                setBarActive(false);
              }}
            >
              <MapOutlined />
            </Button>
            <Button
              variant={barActive ? "contained" : "outlined"}
              onClick={() => {
                setMapActive(false);
                setBarActive(true);
              }}
            >
              <BarChartIcon />
            </Button>
            <Box flexGrow={1}></Box>
            <FormControl>
              <InputLabel size="small">Sub County</InputLabel>
              <Select
                label="Sub County"
                size="small"
                sx={{ minWidth: "120px" }}
              >
                {subcounties.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel size="small">Ward</InputLabel>
              <Select label="Ward" size="small" sx={{ minWidth: "120px" }}>
                {wards.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Divider sx={{ my: 1 }} />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography
                variant="body1"
                sx={{ fontWeight: "800" }}
                gutterBottom
              >
                {mapActive ? "Subcounty Rate Payment" : "Subcounty Data"}
              </Typography>
              <Box sx={{ height: 500 }}>
                {/* sc rate payment */}
                {mapActive && (
                  <MapItem id="subcounty-map" url="/api/subcounties/billing" />
                )}
                {barActive && <StackedBarChart data={subCountyData} />}
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography
                variant="body1"
                sx={{ fontWeight: "800" }}
                gutterBottom
              >
                {mapActive ? "Ward Rate Payment" : "Ward Data"}
              </Typography>
              <Box sx={{ height: 500 }}>
                {/* ward map rate payment */}
                {mapActive && (
                  <MapItem id="ward-map" url="/api/wards/billing" />
                )}
                {barActive && <StackedBarChart data={wardData} />}
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = (props) => {
  return (
    <Grid2 size={{ md: 2.4, xs: 12, sm: 2 }}>
      <Card sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}>
        <CardContent>
          <Typography variant="subtitle" gutterBottom>
            {props.title}
          </Typography>
          <Typography color="primary" variant="h5" mt={2}>
            {props.value.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </CardContent>
      </Card>
    </Grid2>
  );
};

const RevenueItem = (props) => {
  return (
    <Card
      sx={{
        borderRadius: "12px",
        position: "relative",
        boxShadow: "0px 10px 30px #60606040",
      }}
    >
      <CardContent>
        <Box gap={2} display="flex" mb={2}>
          <Box
            sx={{
              height: "32px",
              width: "32px",
              backgroundColor: "orange",
              borderRadius: "50%",
              display: "grid",
              placeContent: "center",
              mb: 1,
            }}
          >
            {props.icon}
          </Box>
          <Typography color="primary" variant="h6">
            KSh{" "}
            {props.value.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </Box>
        <Typography
          sx={{
            position: "absolute",
            right: "1em",
            bottom: "1em",
            fontSize: "small",
          }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            position: "absolute",
            left: "1em",
            bottom: "1em",
            fontSize: "small",
          }}
          variant="subtitle"
          gutterBottom
        >
          {props.year}
        </Typography>
      </CardContent>
    </Card>
  );
};

const StackedBarChart = (props) => {
  return (
    <BarChart
      height={500}
      series={[
        {
          data: props.data.map((item) => item.totalArrears),
          label: "Total Arrears",
          stack: "total",
          color: "#381313",
        },
        {
          data: props.data.map((item) => item.totalBilledAmount),
          label: "Total Billed Amount",
          stack: "total",
          color: "#00A800",
        },
        {
          data: props.data?.map((item) => item.totalRevenue),
          label: "Total Revenue",
          stack: "total",
          color: "#49759c",
        },
      ]}
      xAxis={[
        {
          scaleType: "band",
          data: props.data?.map((item) => item.label),
        },
      ]}
    />
  );
};
